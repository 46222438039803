import React from 'react'
import './styles.scss'

class Rewards extends React.PureComponent {
  render() {
    return (
      <section className="position-relative w-100 rewards-section d-flex">
        <div className="rewards-slider">
          <div className="rewards">
            <div className="rewards-box mx-auto">
              <img
                src="https://images.contentstack.io/v3/assets/blt7d4c4f4a1bf5a819/bltd00ed89915d3c07f/623ada6d4fd402277f39379a/award-img1.png"
                alt=""
              />
            </div>
            <div className="rewards-title">
              <h4>Nu Skin Enterprises</h4>
            </div>
            <div className="award-description">
              <p>World's #1 Brand for Beauty Device Systems</p>
            </div>
            <div className="rewards-sub-text">
              <p>Euromonitor International Limited</p>
            </div>
          </div>
          <div className="rewards">
            <div className="rewards-box mx-auto">
              <img
                src="https://images.contentstack.io/v3/assets/blt7d4c4f4a1bf5a819/blt074d8e2372ce8507/623add7ef791412cd5f7a263/award-img2.png"
                alt=""
              />
            </div>
            <div className="rewards-title">
              <h4>ageLOC® Boost</h4>
            </div>
            <div className="award-description">
              <p>2021 Bronze Winner</p>
            </div>
            <div className="rewards-sub-text">
              <p>Edison Awards</p>
            </div>
          </div>
          <div className="rewards">
            <div className="rewards-box mx-auto">
              <img
                src="https://images.contentstack.io/v3/assets/blt7d4c4f4a1bf5a819/blt79988bf42e1c584f/623adf0729381a2cd48cb237/award-img3.png"
                alt=""
              />
            </div>
            <div className="rewards-title">
              <h4>Epoch Baobab Body Butter</h4>
            </div>
            <div className="award-description">
              <p>2021 Winner</p>
            </div>
            <div className="rewards-sub-text">
              <p>Global Green Beauty Awards</p>
            </div>
          </div>
          <div className="rewards">
            <div className="rewards-box mx-auto">
              <img
                src="https://images.contentstack.io/v3/assets/blt7d4c4f4a1bf5a819/blt8de99f375ab51afe/621cf351c547bf13f7cbd795/2020_InStyle_ReadersChoice_20_(1).png"
                alt=""
              />
            </div>
            <div className="rewards-title">
              <h4>Nu Skin Facial Spa</h4>
            </div>
            <div className="award-description">
              <p>2020 Readers’ Choice Beauty Awards</p>
            </div>
            <div className="rewards-sub-text">
              <p>InStyle</p>
            </div>
          </div>
          <div className="rewards">
            <div className="rewards-box mx-auto">
              <img
                src="https://images.contentstack.io/v3/assets/blt7d4c4f4a1bf5a819/blt57f4103ed781f6cd/621cf352669c5d12b94690b3/2021_Pure_Beauty_Award_Logo_(To_Be_Clear_Pure_Cleansing_Gel).png"
                alt=""
              />
            </div>
            <div className="rewards-title">
              <h4>Nutricentials Pure Cleansing Gel</h4>
            </div>
            <div className="award-description">
              <p>2021 Finalist</p>
            </div>
            <div className="rewards-sub-text">
              <p>Pure Global Beauty Awards</p>
            </div>
          </div>
          <div className="rewards">
            <div className="rewards-box mx-auto">
              <img
                src="https://images.contentstack.io/v3/assets/blt7d4c4f4a1bf5a819/blt4f829f3aff175013/621cf352ae198a13f6e786d8/2021_New_Beauty_(1).png"
                alt=""
              />
            </div>
            <div className="rewards-title">
              <h4>ageLOC® LumiSpa</h4>
            </div>
            <div className="award-description">
              <p>2021 Best Cleansing Device</p>
            </div>
            <div className="rewards-sub-text">
              <p>NewBeauty Awards</p>
            </div>
          </div>
          <div className="rewards">
            <div className="rewards-box mx-auto">
              <img
                src="https://images.contentstack.io/v3/assets/blt7d4c4f4a1bf5a819/bltcecf4758dead48d9/621cf351669c5d12b94690af/2021_Big-INNOVATION.png"
                alt=""
              />
            </div>
            <div className="rewards-title">
              <h4>ageLOC® Boost</h4>
            </div>
            <div className="award-description">
              <p></p>
            </div>
            <div className="rewards-sub-text">
              <p>BIG Innovation Award</p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export { Rewards }
export default Rewards
