import React from 'react'
import { observer } from 'mobx-react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { tryJSONParse } from 'src/utils'
import { pageNames } from 'src/routes/pathParams'

@observer
class Image extends React.Component {
  static defaultProps = {
    properties: {
      backgroundColor: '#FFF',
      fullWidth: false,
      align: 'center',
      className: '',
      spacing: 0,
      ImageURL: '',
      maxWidth: 'inherit',
      CTA: '',
    },
  }

  render() {
    const {
      backgroundColor,
      fullWidth,
      align,
      className,
      spacing,
      maxWidth,
      ImageURL,
      CTA,
    } = this.props?.properties || ''

    const imageView = (
      <Container
        fluid={tryJSONParse(fullWidth)}
        className={`p-0 ${className}`}
        style={{
          backgroundColor,
        }}>
        <Link
          className={`d-flex justify-content-${align}`}
          to={`${pageNames.catalog}/${CTA}`}>
          <img
            alt=""
            className="position-relative "
            style={{
              padding: spacing,
              width: maxWidth,
            }}
            src={ImageURL}
          />
        </Link>
      </Container>
    )
    return imageView
  }
}
export { Image }
