import React from 'react'
import { Link } from 'react-router-dom'
import ReactSlick from 'react-slick'
import { GoPrimitiveDot } from 'react-icons/go'
import { Image, Container, Nav } from 'react-bootstrap'
import { pageNames } from 'src/routes/pathParams'
import { setResponsiveValues, isValidUrl, tryJSONParse } from 'src/utils'
import './styles.scss'

class MediaCarousel extends React.Component {
  static defaultProps = {
    items: [],
    properties: {
      gridGap: 10,
      desktopColSpan: 4,
      tabletColSpan: 3,
      mobileColSpan: 2,
      hasArrows: true,
      hasBullets: true,
    },
  }
  transformCarouselList = items => {
    return items.map(item => {
      const url = item?.url || ''
      let navigationURL = ''
      if (url) {
        navigationURL = url.match(/http(s*):\/\//)
          ? url
          : `${pageNames.catalog}/${url}`
      }
      return {
        properties: {
          imageURL: item.src || '',
          url: navigationURL,
        },
      }
    })
  }

  getSlidesToShow = () => {
    const { desktopColSpan, tabletColSpan, mobileColSpan } =
      this.props.properties
    const colSpan = setResponsiveValues(
      desktopColSpan,
      tabletColSpan,
      mobileColSpan
    )

    try {
      const parsedValue = JSON.parse(colSpan)
      return parsedValue
    } catch (e) {
      return colSpan
    }
  }

  render() {
    const transformedList = this.transformCarouselList(this.props.items)
    const { hasArrows, hasBullets, fullWidth = true } = this.props.properties
    const slidesToShow = this.getSlidesToShow()

    return (
      <Container className="p-0" fluid={tryJSONParse(fullWidth)}>
        <ReactSlick
          arrows={hasArrows === 'true' || hasArrows === true}
          dots={hasBullets === 'true' || hasBullets === true}
          draggable={true}
          infinite={true}
          speed={500}
          slidesToShow={slidesToShow}
          slidesToScroll={slidesToShow}
          focusOnSelect={true}
          initialSlide={0}
          autoplay={true}
          autoplaySpeed={2000}
          dotsClass="d-flex justify-content-center dots-style pt-3 px-0"
          customPaging={() => {
            return (
              <span className="dot-class">
                <GoPrimitiveDot size="1.5rem" />
              </span>
            )
          }}
          className="media-carousel">
          {transformedList.map((image, index) => {
            const { imageURL, url } = image.properties
            if (url) {
              const attributes = isValidUrl(url)
                ? { href: url, target: '_blank' }
                : { as: Link, to: url }
              return (
                <Nav.Link className="p-0" {...attributes}>
                  <Image src={imageURL} key={`carousel-${index}`} fluid />
                </Nav.Link>
              )
            } else {
              return <Image src={imageURL} key={`carousel-${index}`} fluid />
            }
          })}
        </ReactSlick>
      </Container>
    )
  }
}

export default MediaCarousel
export { MediaCarousel }
