import { i18nTranslate } from 'src/utils'

const videoList = () => {
  return {
    poster: '//raderain-cdn.sirv.com/devs/home/CHARLI.jpg',
    src: '//raderain-cdn.sirv.com/T-Giant/Video/Video_T-Giant_3Girls.mp4',
    hasNativeControls: 'true',
    shouldAutoPlay: 'false',
    shouldLoop: 'true',
    slogan: i18nTranslate(
      'video.slogan',
      'Proper care to keep your tees wrinkle-free (From  fixture)'
    ),
    title: i18nTranslate('video.title', 'How to Fold a T-Shirt'),
    shareTitle: i18nTranslate('video.shareTitle', 'Share'),
    videoLength: '0:10',
    shouldShowVideoInformation: 'true',
    hasPlayButton: 'false',
  }
}
export { videoList }
