import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { tryJSONParse } from 'src/utils'

const borderRadius = {
  normal: '',
  pill: 'rounded-pill',
  circle: 'rounded-circle',
}

class ButtonGroup extends React.Component {
  renderButton(item) {
    const {
      text,
      variant,
      appearance,
      outerSpacing,
      innerSpacing,
      wrapperWidth,
      align,
    } = item

    return (
      <div
        className={`d-flex justify-content-${align}`}
        style={{ padding: outerSpacing, width: wrapperWidth }}>
        <Button
          variant={variant}
          style={{ padding: innerSpacing }}
          className={`${borderRadius[appearance]}`}>
          {text}
        </Button>
      </div>
    )
  }

  render() {
    const { fullWidth } = this.props.properties
    const { items } = this.props
    return (
      <Container className="p-0" fluid={tryJSONParse(fullWidth)}>
        {items.map(this.renderButton)}
      </Container>
    )
  }
}

export { ButtonGroup }
