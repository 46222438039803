import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { Container, Row, Col } from 'react-bootstrap'
import ContentStack from 'src/api/contentStack'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import { ImageComponent } from 'src/views/components'
import {
  getBynderImage,
  getBynderAltText,
  getBynderAltTextSrc,
} from 'src/utils'
import './styles.scss'

@observer
class HomePageTitleBanner extends React.Component {
  @observable homeTitle = []
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = () => {
    const uid = this.props?.data?.disclaimer?.[0]?.uid || ''

    ContentStack.getReference('web_page_title_banner', uid, this.props).then(
      response => (this.homeTitle = response || [])
    )
  }

  // getBynderImage = (bynderLeftImage = {}) => {
  //   return (
  //     bynderLeftImage?.mini ||
  //     bynderLeftImage?.Small ||
  //     bynderLeftImage?.webImage
  //   )
  // }

  getStyleGuides = () => {
    const bynderLeftImage = getBynderImage({
      bynderImage: this.homeTitle?.left_image?.[0],
    })
    const bynderRightImage = getBynderImage({
      bynderImage: this.homeTitle?.right_image?.[0],
    })

    return {
      backgroundColor: this.homeTitle?.background_color || '#FFFFFF',
      fontColor: this.homeTitle?.font_color || '',
      leftImageURL: bynderLeftImage || imagePlaceholder,
      rightImageURL: bynderRightImage || imagePlaceholder,
      leftImageAlt:
        this.homeTitle?.left_image_alt_tag ||
        getBynderAltText(this.homeTitle?.left_image?.[0]) ||
        '',
      leftImageAltTextResolution:
        this.homeTitle?.left_image_alt_tag !== '' &&
        this.homeTitle?.left_image_alt_tag !== null
          ? 'cs'
          : getBynderAltTextSrc(this.homeTitle?.left_image?.[0]),
      rightImageAlt:
        this.homeTitle?.right_image_alt_tag ||
        getBynderAltText(this.homeTitle?.right_image?.[0]) ||
        '',
      rightImageAltTextResolution:
        this.homeTitle?.right_image_alt_tag !== '' &&
        this.homeTitle?.right_image_alt_tag !== null
          ? 'cs'
          : getBynderAltTextSrc(this.homeTitle?.right_image?.[0]),
    }
  }

  render() {
    const data = {
      title: this.homeTitle?.h1_title || '',
    }
    const styles = this.getStyleGuides()

    return (
      <Container fluid className="mobile-title-section w-100 p-0">
        <Row
          className="homepage-title"
          style={{ backgroundColor: styles?.backgroundColor }}>
          <Col
            md="auto"
            className="float-left homepage-title-left-img d-hide d-lg-show p-0">
            {/* <Image alt={styles?.leftImageAlt} src={styles?.leftImageURL} /> */}
            <ImageComponent
              src={styles?.leftImageURL}
              alt={styles?.leftImageAlt}
              // title={data?.title}
              height="70px"
              width="63.2px"
              dataAltSrc={styles?.leftImageAltTextResolution}
            />
          </Col>
          <Col md="12" lg="10" className="homepage-title-heading">
            <h1
              style={{
                ...(styles?.fontColor
                  ? { color: '#ffffff' }
                  : { color: '#5F5F5F' }),
              }}>
              {data?.title}
            </h1>
          </Col>
          <Col
            md="auto"
            className="float-right homepage-title-right-img d-hide d-lg-show p-0">
            {/* <Image alt={styles?.rightImageAlt} src={styles?.rightImageURL} /> */}
            <ImageComponent
              src={styles?.rightImageURL}
              alt={styles?.rightImageAlt}
              // title={data?.title}
              height="70px"
              width="63.2px"
              dataAltSrc={styles?.rightImageAltTextResolution}
            />
          </Col>
        </Row>
      </Container>
    )
  }
}

export { HomePageTitleBanner }
export default HomePageTitleBanner
