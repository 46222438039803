import React from 'react'
import ReactSlick from 'react-slick'
import { GoPrimitiveDot } from 'react-icons/go'
import { Image, Container, Row, Col, Button } from 'react-bootstrap'
import { pageNames } from 'src/routes/pathParams'
import { setResponsiveValues, tryJSONParse } from 'src/utils'
import './styles.scss'

class ImageAndTextCarousel extends React.Component {
  static defaultProps = {
    items: [],
    properties: {
      gridGap: 10,
      desktopColSpan: 2,
      tabletColSpan: 1,
      mobileColSpan: 1,
      // itemHeight: 300,
      hasArrows: true,
      hasBullets: false,
      // isLoopCarousel: false,
      // isCircularCarousel: false,
    },
  }
  transformCarouselList = items => {
    return items.map(item => {
      const url = item?.Navigateto || ''
      let navigationURL = ''
      if (url) {
        navigationURL = url.match(/http(s*):\/\//)
          ? url
          : `${pageNames.catalog}/${url}`
      }
      return {
        properties: {
          Title: item.Title || '',
          Description: item.Description || '',
          imageURL: item.imageURL || '',
          Buttonlabel: item.ButtonContent || 'Click',
          alignment: item.alignment || 'left',
          url: navigationURL,
        },
      }
    })
  }

  getSlidesToShow = () => {
    const { desktopColSpan, tabletColSpan, mobileColSpan } =
      this.props.properties
    const colSpan = setResponsiveValues(
      desktopColSpan,
      tabletColSpan,
      mobileColSpan
    )

    try {
      const parsedValue = JSON.parse(colSpan)
      return parsedValue
    } catch (e) {
      return colSpan
    }
  }

  renderImageandTexttop = item => {
    return (
      <Col className="widget-cont">
        <Col
          xs={12}
          className="justify-content-center align-items-center d-flex">
          <div className="image-cont">
            <Image
              src={item.imageURL}
              alt="image"
              className="image mh-100 mw-100"
            />
          </div>
        </Col>
        <Col
          xs={12}
          className={`text-wrap flex-column justify-content-center  d-flex p-4 ${
            item.alignment === 'top' ? 'top' : ''
          }`}>
          <div className="title-top pb-4">{item.Title}</div>
          <h6 className="text-secondary text-desc">{item.Description}</h6>
        </Col>
      </Col>
    )
  }

  renderImageandTextbottom = item => {
    return (
      <Col className="widget-cont">
        <Col
          xs={12}
          className={`text-wrap flex-column justify-content-center align-items-center d-flex p-4 ${
            item.alignment === 'bottom' ? 'bottom' : ''
          }`}>
          <div className="title pb-4">{item.Title}</div>
          <div
            dangerouslySetInnerHTML={{
              __html: item.Description,
            }}
          />
          {item.Buttonlabel && (
            <Button variant="outline-primary">{item.Buttonlabel}</Button>
          )}
        </Col>
        <Col
          xs={12}
          className="justify-content-center align-items-center d-flex">
          <div className="image-cont">
            <Image
              src={item.imageURL}
              alt="image"
              className="image mh-100 mw-100"
            />
          </div>
        </Col>
      </Col>
    )
  }

  renderImageandText = item => {
    return (
      <>
        <Row className="widget-cont">
          <Col
            xs={12}
            md={6}
            className="justify-content-center align-items-center px-5 d-flex">
            <div className="image-cont">
              <Image
                src={item.imageURL}
                alt="image"
                className="image mh-100 mw-100"
              />
            </div>
          </Col>
          <Col
            xs={12}
            md={6}
            className={`text-wrap flex-column justify-content-center align-items-center d-flex p-4 ${
              item.alignment === 'right' ? 'right' : ''
            }`}>
            <div className="title pb-3 ">{item.Title}</div>
            <div
              className="image_description pb-5 px-5"
              dangerouslySetInnerHTML={{
                __html: item.Description,
              }}
            />
          </Col>
        </Row>
      </>
    )
  }

  render() {
    const transformedList = this.transformCarouselList(this.props.items)
    const { hasArrows, hasBullets, fullWidth } = this.props.properties
    const slidesToShow = this.getSlidesToShow()
    return (
      <Container className="p-0 mt-3" fluid={tryJSONParse(fullWidth)}>
        <ReactSlick
          arrows={hasArrows === 'true' || hasArrows === true}
          dots={hasBullets === 'true' || hasBullets === true}
          draggable={true}
          infinite={true}
          speed={500}
          slidesToShow={slidesToShow}
          slidesToScroll={slidesToShow}
          focusOnSelect={true}
          initialSlide={0}
          // centerMode={true}
          dotsClass="d-flex justify-content-center dots-style pt-3 px-0"
          customPaging={() => {
            return (
              <span className="dot-class">
                <GoPrimitiveDot size="1.5rem" />
              </span>
            )
          }}
          className="media-carousel">
          {transformedList.map((item, index) => {
            switch (item.properties.alignment) {
              case 'top':
                return this.renderImageandTexttop(item.properties)
              case 'bottom':
                return this.renderImageandTextbottom(item.properties)
              case 'right':
              case 'left':
                return this.renderImageandText(item.properties)
              default:
                return <div />
            }
          })}
        </ReactSlick>
      </Container>
    )
  }
}

export default ImageAndTextCarousel
export { ImageAndTextCarousel }
