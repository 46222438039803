import React from 'react'
import { Container, Row } from 'react-bootstrap'
import './styles.scss'

class Separator extends React.PureComponent {
  getDataModal = () => {
    return {
      heading: this.props?.data?.heading || '',
      bigTitle: this.props?.data?.big_title || '',
    }
  }

  render() {
    const data = this.getDataModal()

    return (
      <Container fluid className="separator-section w-100 p-0">
        <Row className="separator-container">
          <div className="hori-line"></div>
          <h4 className="h4-bold subhead-l">{data?.heading}</h4>
          {data?.bigTitle && <h3 className="heading-1">{data?.bigTitle}</h3>}
        </Row>
      </Container>
    )
  }
}

export { Separator }
export default Separator
