import React, { Component } from 'react'
import Slider from 'react-slick'
import TagManager from 'react-gtm-module'
import { IS_BROWSER } from 'config/appConfig'
import { Card, Row, Col } from 'react-bootstrap'
import NuskinArrowRight from 'src/views/components/Icons/NuskinArrowRight'
import NuskinArrowLeft from 'src/views/components/Icons/NuskinArrowLeft'
import {
  renderLinkCheck,
  getBynderImage,
  getBynderAltText,
  getBynderAltTextSrc,
} from 'src/utils'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import { PageLink } from 'src/views/components/PageLink'
import { ImageComponent } from 'src/views/components'
import './styles.scss'

class CategorySection extends Component {
  constructor(props) {
    super(props)
    this.slider = React.createRef(null)
    this.state = { isDragging: false }
  }

  TITLE_DETAIL = 'TITLE_DETAIL'
  CATEGORY_DETAIL = 'CATEGORY_DETAIL'

  setSliderRef = slider => {
    this.slider.current = slider
  }
  // fix for ADA as child anchor tags are also included in tab navigation
  // inside child elements
  methodToReuseAdaFix = () => {
    if (IS_BROWSER) {
      const mailTitle = this.props?.data?.main_title
        ?.toLowerCase()
        ?.replace(/ /g, '-')
      document
        .querySelectorAll(`#${mailTitle} .slick-list a`)
        .forEach(anchorTag => anchorTag.setAttribute('tabindex', '-1'))

      document
        .querySelectorAll(`#${mailTitle} div[aria-hidden=false] a`)
        .forEach(anchorTag => anchorTag.removeAttribute('tabindex', '-1'))
    }
  }
  handleTabIndex = () => {
    this.methodToReuseAdaFix()
  }

  handleTabIndexAndGTMCarouselClick = () => {
    this.setState({ isDragging: false })
    this.methodToReuseAdaFix()
    TagManager.dataLayer({
      dataLayer: {
        event: 'carouselClicked',
        pagePath: '/',
        pageTitle: 'Home',
        section: 'Our Top Categories',
        platform: 'equinox',
      },
    })
  }
  getCustomDots = dots => {
    const customDots = []
    this.props?.data?.category_blocks?.forEach((block, index) => {
      customDots.push(
        <li key={`productDot_${index}`} {...dots?.[index]?.props}>
          <button
            aria-label={`product image ${index + 1} of ${
              this.props?.data?.category_blocks.length
            }`}
            onClick={dots?.[index]?.props?.children?.props?.onClick}>
            {index + 1}
          </button>
        </li>
      )
    })

    return <ul className="slick-dots">{customDots}</ul>
  }

  onSwipe = () => {
    if (this.slider?.current?.innerSlider) {
      this.slider.current.innerSlider.clickable = true
    }
  }

  getDataModal = (dataKey, category = {}) => {
    const originalUrl = this.props?.staticContext?.originalUrl
    switch (dataKey) {
      case this.TITLE_DETAIL:
        return {
          mainTitle: this.props?.data?.main_title || '',
          id:
            this.props?.data?.main_title?.toLowerCase()?.replace(/ /g, '-') ||
            '',
          titleLinkInfo: renderLinkCheck(
            this.props?.data?.equinox_view_all_link?.href ||
              this.props?.data?.view_all_link?.href ||
              '',
            originalUrl
          ),
          title:
            this.props?.data?.equinox_view_all_link?.title ||
            this.props?.data?.view_all_link?.title ||
            '',
          categoryBlocks: this.props?.data?.category_blocks || [],
        }
      case this.CATEGORY_DETAIL:
        return {
          categoryLinkInfo: renderLinkCheck(
            category?.category?.equinox_link?.href ||
              category.category?.link?.href,
            originalUrl
          ),
          title:
            category?.category?.equinox_link?.title ||
            category.category?.link?.title ||
            '',
        }

      default:
        return {}
    }
  }

  getStyleGuides = (dataKey, category = {}) => {
    const bynderImage = getBynderImage({
      bynderImage: category.category?.bynder_background_image?.[0],
    })

    const categoryAltText =
      category.category?.image_description ||
      getBynderAltText(category.category?.bynder_background_image?.[0]) ||
      'category image'

    return {
      backgroundImage: bynderImage || imagePlaceholder,
      backgroundImageAlt: categoryAltText,
      imageAltTextResolution:
        category.category?.image_description !== '' &&
        category.category?.image_description !== null
          ? 'cs'
          : getBynderAltTextSrc(
              category.category?.bynder_background_image?.[0]
            ),
    }
  }

  getSliderSettings = () => {
    return {
      dots: true,
      appendDots: dots => this.getCustomDots(dots),
      infinite: true,
      speed: 200,
      useCSS: true,
      cssEase: 'linear',
      swipeToSlide: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      onInit: this.handleTabIndex,
      afterChange: this.handleTabIndexAndGTMCarouselClick,
      beforeChange: () => {
        this.setState({ isDragging: true })
      },
      arrows: false,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 4,
            infinite: true,
            centerMode: true,
            centerPadding: '40px',
          },
        },
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 3,
            centerMode: true,
            centerPadding: '40px',
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            centerMode: true,
            centerPadding: '130px',
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            //centerPadding: '70px',
            variableWidth: true,
          },
        },
      ],
      onSwipe: this.onSwipe.bind(this),
    }
  }

  renderCategoryCard = (category, index) => {
    const data = this.getDataModal(this.CATEGORY_DETAIL, category)
    const styles = this.getStyleGuides(this.CATEGORY_DETAIL, category)
    return (
      <Card
        key={`categorySec-${index}`}
        className="homepage category-section-card">
        <PageLink
          linkInfo={data?.categoryLinkInfo}
          className="text-decoration-none"
          onClick={e => this.state.isDragging && e.preventDefault()}>
          {/* <Card.Img
            className="category-img"
            variant="top"
            src={styles?.backgroundImage}
            alt={styles?.backgroundImageAlt}
            title={data?.title}
          /> */}
          <ImageComponent
            testid="qa-product-image"
            src={styles?.backgroundImage}
            alt={styles?.backgroundImageAlt}
            dataAltSrc={styles?.imageAltTextResolution}
            title={data?.title}
            height={'260px'}
            width={'230px'}
          />
          <Card.Body>
            <Card.Subtitle className="head-3 heading-3">
              {data?.title}
            </Card.Subtitle>
          </Card.Body>
        </PageLink>
      </Card>
    )
  }

  renderArrows = () => {
    return (
      <div className="slider-arrow">
        <div
          className="arrow-btn prev"
          onClick={() => this.slider?.current?.slickPrev()}>
          <NuskinArrowLeft />
        </div>
        <div
          className="arrow-btn next"
          onClick={() => this.slider?.current?.slickNext()}>
          <NuskinArrowRight />
        </div>
      </div>
    )
  }

  render() {
    const data = this.getDataModal(this.TITLE_DETAIL)
    const settings = this.getSliderSettings()

    // const sliderRef = slider => {
    //   this.slider = slider
    // }
    return (
      <>
        <Row className="category-head justify-content-center mx-0">
          <h3 className="heading-1 mb-0 mt-5">{data?.mainTitle}</h3>
        </Row>
        <Row id={data?.id} className="category-section-slider-wrapper">
          <Col md="auto" className="viewmain d-none d-md-block px-0 mx-0">
            <PageLink
              linkInfo={data?.titleLinkInfo}
              className="text-decoration-none">
              <h6 className="viewsec paragraph-m">{data?.title}</h6>
            </PageLink>
          </Col>
          <div>{this.renderArrows()}</div>
          {data?.categoryBlocks && data?.categoryBlocks?.length && (
            <div>
              <Slider
                {...settings}
                dots={true}
                className="category-selection"
                ref={slider => (this.slider.current = slider)}>
                {data?.categoryBlocks?.map(this.renderCategoryCard)}
              </Slider>
            </div>
          )}
          <Col className="mob-view-all d-block d-md-none px-0">
            <PageLink
              linkInfo={data?.titleLinkInfo}
              className="text-decoration-none">
              <h6 className="viewsec m-0 cta">{data?.title}</h6>
            </PageLink>
          </Col>
        </Row>
      </>
    )
  }
}

export { CategorySection }
export default CategorySection
