import React from 'react'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import { Card, Container } from 'react-bootstrap'
import ContentStack from 'src/api/contentStack'
import './styles.css'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        border: '1px solid #000',
        right: '0',
        top: '0',
      }}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        border: '1px solid #000',
        left: '90%',
        top: '0',
      }}
      onClick={onClick}
    />
  )
}
class SocialMedia extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      socialSection: [],
    }
  }
  componentDidMount() {
    this.getPage()
  }

  getPage = () => {
    ContentStack.getReference(
      'social_media_slider',
      this.props.data['social_media_slider'][0]['uid'],
      {},
      'US-en'
    ).fetch(response => {
      this.setState({
        socialSection: response?.social_media_blocks,
      })
    })
  }

  renderSocialMedia = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            arrows: false,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }
    return (
      <div className="socialmedia-section">
        <Container>
          <div>
            <Slider {...settings} dots={true}>
              {this.state.socialSection.map(post => {
                return (
                  <Card key={post.id} className="category-section-card">
                    <Link
                      to={post['social_media']['link']['url']}
                      className="text-decoration-none">
                      <Card.Img
                        variant="top"
                        src={post['social_media']['background_image']['url']}
                      />
                    </Link>

                    <Card.Body>
                      <Link
                        to={post['social_media']['link']['url']}
                        className="text-decoration-none">
                        <Card.Subtitle className="heading-3">
                          {post['social_media']['link']['title']}
                        </Card.Subtitle>
                      </Link>
                    </Card.Body>
                  </Card>
                )
              })}
            </Slider>
          </div>
        </Container>
      </div>
    )
  }
  render() {
    return <div>{this.renderSocialMedia()}</div>
  }
}
export { SocialMedia }
export default SocialMedia
