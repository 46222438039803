import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
/* import { AiOutlineEye as EyeIconNew } from 'react-icons/ai' */
import { ImageComponent } from 'src/views/components'
import { getBynderAltTextSrc, renderLinkCheck } from 'src/utils'
import { PageLink } from 'src/views/components'
import { getBynderImage, getBynderAltText } from 'src/utils'

import './styles.scss'

class Features extends React.PureComponent {
  getDataModal = () => {
    return {
      heading: this.props?.data?.heading || '',
      bigTitle: this.props?.data?.big_title || '',
      description: this.props?.data?.description || '',
      featureLink: renderLinkCheck(
        this.props?.data?.equinox_link?.href || this.props?.data?.link?.href
      ),
      title:
        this.props?.data?.equinox_link?.title ||
        this.props?.data?.link?.title ||
        '',
      imageDescription1:
        this.props?.data?.img_1_description ||
        getBynderAltText(this.props?.data?.bynder_image_1?.[0]) ||
        'features new kit image',
      imageDescription2:
        this.props?.data?.img_2_description ||
        getBynderAltText(this.props?.data?.bynder_image_2?.[0]) ||
        'features bottom image',
      imageURL1:
        getBynderImage({
          bynderImage: this.props?.data?.bynder_image_1?.[0],
        }) || imagePlaceholder,
      imageURL2:
        getBynderImage({
          bynderImage: this.props?.data?.bynder_image_2?.[0],
        }) || imagePlaceholder,
      imageAltTextResolution1:
        this.props?.data?.img_1_description !== '' &&
        this.props?.data?.img_1_description !== null
          ? 'cs'
          : getBynderAltTextSrc(this.props?.data?.bynder_image_1?.[0]),
      imageAltTextResolution2:
        this.props?.data?.img_2_description !== '' &&
        this.props?.data?.img_2_description !== null
          ? 'cs'
          : getBynderAltTextSrc(this.props?.data?.bynder_image_2?.[0]),
    }
  }

  render() {
    const data = this.getDataModal()
    return (
      <Container className="features-section position-relative w-100 d-flex flex-column px-0">
        <Row className="features-new position-relative w-100 d-inline-block mx-auto">
          <Row
            className="features-new-kit position-relative d-flex flex-column flex-wrap align-items-start align-items-md-center justify-content-start justify-content-lg-center"
            style={{ background: this.props?.data?.background_color }}>
            <Col className="features-inner-text">
              <h6 className="paragraph-s ">{data?.heading}</h6>
              <h3 className="heading-1">{data?.bigTitle}</h3>
              <h4 className="paragraph-l">{data?.description}</h4>
              <PageLink
                className="cta text-uppercase"
                linkInfo={data?.featureLink}>
                <strong>{data?.title}</strong>
              </PageLink>
              <div
                md="auto"
                className="features-bottom-image d-none d-md-block px-0">
                {/* <Image src={data?.imageURL2} alt={data?.imageDescription2} /> */}
                <ImageComponent
                  src={data?.imageURL2}
                  alt={data?.imageDescription2}
                  dataAltSrc={data?.imageAltTextResolution2}
                />
              </div>
            </Col>
          </Row>
          <Row className="features-new-kit-image px-0">
            {/* <Image src={data?.imageURL1} alt={data?.imageDescription1} /> */}
            <ImageComponent
              src={data?.imageURL1}
              alt={data?.imageDescription1}
              dataAltSrc={data?.imageAltTextResolution1}
            />
            <Col className="features-bottom-image d-block d-md-none px-0">
              {/* <Image src={data?.imageURL2} alt={data?.imageDescription2} /> */}
              <ImageComponent
                src={data?.imageURL2}
                alt={data?.imageDescription2}
                dataAltSrc={data?.imageAltTextResolution2}
              />
            </Col>
          </Row>
        </Row>
      </Container>
    )
  }
}

export { Features }
export default Features
