import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { Col, Container, Row } from 'react-bootstrap'
import ContentStack from 'src/api/contentStack'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import { getBynderImage } from 'src/utils'
import { getBynderAltTextSrc } from 'src/utils'
import './styles.scss'

@observer
class Tagline extends React.Component {
  @observable tagline = {}

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = () => {
    const uid = this.props?.data?.disclaimer?.[0]?.uid || ''

    ContentStack.getReference('home_page_tagline', uid, this.props).then(
      response => {
        if (response) {
          this.tagline = response
        }
      }
    )
  }

  getStyleGuides = () => {
    let mobileImage = getBynderImage({
      bynderImage: this.tagline?.bynder_image_sp?.[0],
    })
    let desktopImage = getBynderImage({
      bynderImage: this.tagline?.bynder_image_pc?.[0],
    })

    return {
      mobileCss: `@media (max-width: 480px) {
        .taglineImgBackground {
            background-image: url(${mobileImage || imagePlaceholder})
        }
      }`,
      desktopCss: `@media (min-width: 481px) {
        .taglineImgBackground {
          background-image: url(${desktopImage || imagePlaceholder});
        }
      }`,
    }
  }

  render() {
    if (!this.tagline) {
      return null
    }

    const data = {
      title: this.tagline?.title || '',
      bigTitle: this.tagline?.text_1 || '',
      source: this.tagline?.text_2 || '',
      dataAltSrc:
        this.tagline?.image_description !== '' &&
        this.tagline?.image_description !== null
          ? 'cs'
          : getBynderAltTextSrc(this.tagline?.bynder_image_sp?.[0]),
    }
    const styles = this.getStyleGuides()

    return (
      <Container>
        {styles?.mobileCss && <style scoped>{styles?.mobileCss}</style>}
        {styles?.desktopCss && <style scoped>{styles?.desktopCss}</style>}

        <Row className="mx-0">
          <Col
            className="tagline mx-auto text-center taglineImgBackground p-0"
            role="img"
            data-alt-text-resolution={data?.dataAltSrc}
            aria-label={data?.title}>
            <div className="tagline-textcontainer">
              <strong>
                <p className="tagline-title">{data?.title}</p>
              </strong>
              <p className="paragraph-l text-uppercase">{data?.bigTitle}</p>
              <p className="tagline-text2">{data?.source}</p>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

export { Tagline }
export default Tagline
