import React from 'react'
import ReactSlick from 'react-slick'
import ContentStack from 'src/api/contentStack'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { catalogContainer } from 'src/models'
import { convertToBoolean, getLiveEventStatus } from 'src/utils'

@observer
class FeaturedProducts extends React.Component {
  @observable productResponse = {}
  settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  }
  constructor(props) {
    super(props)
    this.fetchData()
  }

  fetchProducts = async options => {
    const { productLists } = options || {}
    this.productResponse = await catalogContainer.getProductsInfo({
      itemIds: productLists,
    })
  }

  fetchData = () => {
    const uid = this.props?.data?.disclaimer?.[0]?.uid || 'blt12dacdb24519a5ce'
    let referenceId = 'featured_product_tab'

    if (convertToBoolean(getLiveEventStatus())) {
      referenceId = 'live_event_featured_product_tab'
    }

    ContentStack.getReference(referenceId, uid, this.props).then(response => {
      console.log('response ', response)
      const productIds = response?.blocks?.[0]?.product_tab?.product_slider.map(
        productSlider => {
          return productSlider?.slider_product?.product?.[0]?.uid
        }
      )
      // console.log('productIds ')
      this.fetchProducts({ productLists: productIds })
    })
  }

  // Mock data will be replaced
  productArray = [
    {
      img: 'https://placehold.co/190x190',
      title: 'Test',
      price: '$ 20.00',
      sv: '$ 20.00',
    },
    {
      img: 'https://placehold.co/190x190',
      title: 'Test',
      price: '$ 20.00',
      sv: '$ 20.00',
    },
    {
      img: 'https://placehold.co/190x190',
      title: 'Test',
      price: '$ 20.00',
      sv: '$ 20.00',
    },
    {
      img: 'https://placehold.co/190x190',
      title: 'Test',
      price: '$ 20.00',
      sv: '$ 20.00',
    },
    {
      img: 'https://placehold.co/190x190',
      title: 'Test',
      price: '$ 20.00',
      sv: '$ 20.00',
    },
    {
      img: 'https://placehold.co/190x190',
      title: 'Test',
      price: '$ 20.00',
      sv: '$ 20.00',
    },
    {
      img: 'https://placehold.co/190x190',
      title: 'Test',
      price: '$ 20.00',
      sv: '$ 20.00',
    },
    {
      img: 'https://placehold.co/190x190',
      title: 'Test',
      price: '$ 20.00',
      sv: '$ 20.00',
    },
    {
      img: 'https://placehold.co/190x190',
      title: 'Test',
      price: '$ 20.00',
      sv: '$ 20.00',
    },
    {
      img: 'https://placehold.co/190x190',
      title: 'Test',
      price: '$ 20.00',
      sv: '$ 20.00',
    },
  ]

  renderProductCard(product = {}) {
    return (
      <div className="event-featured-product-card">
        <div className="event-featured-product-img-container">
          <img className="event-featured-product-img" src={product.img} />
        </div>
        <div className="event-featured-product-text">
          <div className="event-featured-product-title">{product.title}</div>
          <div className="event-featured-product-price">{product.price}</div>
          <div className="event-featured-product-sv">{product.sv}</div>
        </div>
      </div>
    )
  }
  render() {
    return (
      <div className="event-featured-container">
        <div className="event-featured-title">Featured Products</div>
        {/* <div>
          {this.props.entry['product_tabs'].map((tab, index) => {
            return (
              <div key={index} className="event-featured-tab">
                <div className="event-featured-tab-title">{tab.uid}</div>
              </div>
            )
          })}
        </div> */}
        <ReactSlick {...this.settings}>
          {this.productResponse?.product &&
            this.productResponse?.product?.map((product, index) => {
              return <div key={index}>{this.renderProductCard(product)}</div>
            })}
        </ReactSlick>
      </div>
    )
  }
}

export { FeaturedProducts }
