import React from 'react'

class AdSpot extends React.Component {
  render() {
    const identifier = this.props?.properties?.identifier || ''
    const htmlContent = this.props?.properties?.htmlContent || ''
    const widgetName = this.props?.widgetName

    const children = htmlContent && (
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    )

    return (
      <div id={identifier} className={`${widgetName} adspot p-2`}>
        {children}
      </div>
    )
  }
}

export { AdSpot }
export default AdSpot
