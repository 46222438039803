import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { ProductCarousel as Carousel } from 'src/views/components'
import { catalogContainer } from 'src/models'

@observer
class ProductCarousel extends React.Component {
  @observable list = []

  constructor(props) {
    super(props)
    this.fetchData(props)
  }

  fetchData = async props => {
    const { categoryId } = props.properties
    if (categoryId) {
      const response = await catalogContainer.getCatalog({
        categoryId,
        size: 10,
      })
      this.list = response && response.product
    } else {
      this.list = props.items
    }
  }

  static defaultProps = {
    items: [],
    properties: {
      gridGap: 10,
      desktopColSpan: 4,
      tabletColSpan: 3,
      mobileColSpan: 2,
      hasArrows: true,
      hasBullets: true,
    },
  }

  render() {
    const properties = this.props?.properties
    return <Carousel list={this.list} {...properties} />
  }
}

export default ProductCarousel
export { ProductCarousel }
