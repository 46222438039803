import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { FiArrowRightCircle as ArrowIconNew } from 'react-icons/fi'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import { renderLinkCheck, getBynderImage, getBynderAltTextSrc } from 'src/utils'
import { ImageComponent } from 'src/views/components'
import './styles.scss'
import { PageLink } from 'src/views/components'

class HighLighterTwo extends React.PureComponent {
  getDataModal = () => {
    return {
      bigTitle: this.props.data?.big_title || '',
      description: this.props.data?.description || '',
      title:
        this.props.data?.equinox_link?.title ||
        this.props.data?.link?.title ||
        '',
    }
  }

  getStyleGuides = () => {
    return {
      imageURL:
        getBynderImage({ bynderImage: this.props.data?.bynder_image?.[0] }) ||
        imagePlaceholder,
      imageAlt: this.props.data?.image_description || 'highlight image',
      linkInfo: renderLinkCheck(
        this.props.data?.equinox_link?.href || this.props.data?.link?.href || ''
      ),
      imageALtResolution:
        this.props.data?.image_description !== '' &&
        this.props.data?.image_description !== null
          ? 'cs'
          : getBynderAltTextSrc(this.props.data?.bynder_image?.[0]),
      // href: renderLinkCheck(this.props.data?.link?.href) || '',
    }
  }

  render() {
    const data = this.getDataModal()
    const styles = this.getStyleGuides()

    return (
      <Container fluid className="highlight-section-two">
        <Row className="highlight-section-2">
          <Col className="highlight-image px-0">
            {/* <Image src={styles?.imageURL} alt={styles?.imageAlt} /> */}
            <ImageComponent
              src={styles?.imageURL}
              alt={styles?.imageAlt}
              dataAltSrc={styles?.imageALtResolution}
            />
          </Col>
          <Col className="highlight-section-two-content">
            <h2 className="heading-2">{data?.bigTitle}</h2>
            <p className="paragraph-m pb-1">{data?.description}</p>
            <PageLink
              className="paragraph-m"
              linkInfo={styles?.linkInfo}
              target="_blank">
              <span>{data?.title}</span>
              <ArrowIconNew className="arrow-icon2 pl-2" />
            </PageLink>
          </Col>
        </Row>
      </Container>
    )
  }
}

export { HighLighterTwo }
export default HighLighterTwo
