import * as React from 'react'
import { Image } from 'react-bootstrap'
import { pageBackgroundItems } from './fixture'
import './styles.scss'

/**
 * @description Page background rendering
 */
class PageBackground extends React.Component {
  static defaultProps = {
    properties: '',
    items: '',
    layout: '',
  }
  render() {
    const { properties } = this.props
    return (
      <section className="page-background-wrapper">
        <figure className="position-absolute w-100 dummy">
          <Image
            className="position-relative w-100"
            src={properties.backgroundImage || pageBackgroundItems.imgSrc}
            alt={pageBackgroundItems.alt}
          />
          <div className="page-background-gradient" />
        </figure>
      </section>
    )
  }
}

export { PageBackground }
