import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Card, Row, Button, Container, Col, Image } from 'react-bootstrap'
import Slider from 'react-slick'
import TagManager from 'react-gtm-module'
import { FiArrowRightCircle as ArrowIconNew } from 'react-icons/fi'
import { AiOutlineShoppingCart as CartIconNew } from 'react-icons/ai'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import { cartContainer, catalogContainer, customerContainer } from 'src/models'
import { builder } from 'src/models/Catalog/builder'
import { ImageComponent } from 'src/views/components'
import NuskinArrowRight from 'src/views/components/Icons/NuskinArrowRight'
import NuskinArrowLeft from 'src/views/components/Icons/NuskinArrowLeft'
import {
  currencyFormat,
  renderLinkCheck,
  getImageFromProperty,
  getBynderAltText,
  getLiveEventStatus,
} from 'src/utils'
import { IS_BROWSER, APPConfig, convertToBoolean } from 'config/appConfig'
import isArray from 'lodash/isArray'
import { i18nTranslate } from 'src/utils'
import { PageLink } from 'src/views/components'
import { ProductsPlaceHolder } from 'src/views/placeholders/ProductsPlaceHolder'
import { pageNames } from 'src/routes/pathParams'
import { getBynderImage, getBynderAltTextSrc } from 'src/utils'
import { catalogDeps } from 'src/deps'
import './styles.scss'
// import PhoneVerification from 'src/views/components/PhoneVerification/PhoneVerification'
// import { ModalView, modalState } from 'src/views/components/ModalView'

function checkAndEnableAWSRecommendations() {
  let isToEnableAWSRecommendation = false
  if (
    APPConfig?.getAppConfig?.()?.enableAWSRecommendation == 'true' &&
    customerContainer.isRegisterUser
  ) {
    isToEnableAWSRecommendation = true
  }
  return isToEnableAWSRecommendation
}

const enableLiveEvent = convertToBoolean(getLiveEventStatus())

const ProductSection = (props = {}) => {
  const isToEnableAWSRecommendation = checkAndEnableAWSRecommendations()
  const products = Array(
    isToEnableAWSRecommendation || props?.isToShowSingleSection ? 4 : 2
  ).fill('product')
  const [productDetails, setProductDetails] = useState({})
  const [isDragging, setIsDragging] = useState(false)

  /*const [showModal, setShowModal] = useState(false)
  const [showModalData, setShowModalData] = useState({})

  const onClose = () => {
    setShowModal(true)
    setShowModalData({
      content:
        'Phone number must be verified, if you leave your changes will not be saved',
      buttonLabel: 'Yes, go to profile',
      continueBtn: 'Actually, continue verification',
    })
  }

  useEffect(() => {
    setShowModalData({
      contentCode: '+1',
      content: '(281) 234-5566',
      buttonLabel: 'Verify my number',
      continueBtn: '',
    })
  }, [])*/

  const handleGTMCarouselClickEvent = () => {
    setIsDragging(false)
    handleTabIndex()
    TagManager.dataLayer({
      dataLayer: {
        event: 'carouselClicked',
        pagePath: '/',
        pageTitle: 'Home',
        section: 'Most Popular Products',
        platform: 'equinox',
      },
    })
  }

  let setTimer = null

  const getSliderSettings = () => {
    if (productDetails.length > 1) {
      return sliderSettings
    } else {
      return { ...sliderSettings, slidesToShow: 1 }
    }
  }

  const getCustomDots = dots => {
    const customDots = []
    productDetails.forEach((block, index) => {
      customDots.push(
        <li key={`productDot_${index}`} {...dots?.[index]?.props}>
          <button
            aria-label={`product image ${index + 1} of ${
              productDetails?.length
            }`}
            onClick={dots?.[index]?.props?.children?.props?.onClick}>
            {index + 1}
          </button>
        </li>
      )
    })
    return <ul className="slick-dots">{customDots}</ul>
  }

  const handleTabIndex = () => {
    if (IS_BROWSER) {
      let anchorTags
      setTimer = setTimeout(() => {
        anchorTags = document.querySelectorAll(`#${PRODUCT_ID} .slick-list a`)
      }, 700)

      if (anchorTags?.length > 0) {
        handleADA()
      } else {
        if (setTimer) {
          clearTimeout(setTimer)
        }
        setTimer = setTimeout(() => {
          handleADA()
        }, 700)
      }
    }
  }

  const handleADA = () => {
    const slickLists = document.querySelectorAll(
      `#${PRODUCT_ID} .slick-list .slick-slide`
    )
    slickLists?.forEach(elem => {
      const isHidden = elem.getAttribute('aria-hidden')
      const aTags = elem.getElementsByTagName('a')[0]
      const btnTags = elem.getElementsByTagName('button')[0]
      if (isHidden === 'true') {
        aTags?.setAttribute?.('tabindex', '-1')
        btnTags?.setAttribute?.('tabindex', '-1')
      } else if (isHidden === 'false') {
        aTags?.removeAttribute?.('tabindex')
        btnTags?.removeAttribute?.('tabindex')
      }
    })
  }

  const getProducts = useCallback(async () => {
    let productLists = []
    let catalogResponseFromAWS = {}
    if (!isToEnableAWSRecommendation) {
      productLists = props.data['product_slider'].map(
        elem => elem?.slider_product?.product?.[0]?.uid
      )
    }
    setProductDetails([])
    if (
      props?.data?.tab_title?.toLowerCase().replace(/\s/g, '') ==
      props?.activeTab?.toLowerCase().replace(/\s/g, '')
    ) {
      if (isToEnableAWSRecommendation) {
        catalogContainer.shouldShowMPPPlaceholder = true
        const recommendationType =
          props?.activeTabId?.toLowerCase().replace(/\s/g, '') ==
          'mpp-best-seller-contents'
            ? 'myRecommendedProducts'
            : 'getAwsMostPopularProducts'
        catalogResponseFromAWS = await catalogContainer.getAWSRecommendations({
          recommendationType: recommendationType,
        })
      } else {
        await catalogContainer.getProductsInfo({ itemIds: productLists })
      }

      // For now filtering the data based on the conditions provided
      // if (
      //   isToEnableAWSRecommendation &&
      //   catalogContainer.searchProductResponse?.length > 0
      // ) {
      //   console.log('catalogResponseFromAWS?.product ', catalogResponseFromAWS)
      //   setProductDetails(catalogResponseFromAWS?.product)
      // } else
      if (catalogContainer.searchProductResponse?.length > 0) {
        const productsToRender = copyAndFilter(
          catalogContainer.searchProductResponse
        )
        const sortMarkets = (productsToRender = [], productLists = []) => {
          if (isArray(productsToRender) && isArray(productLists)) {
            return [...productsToRender].sort((a, b) => {
              if (a?.identifier && b?.identifier) {
                return (
                  productLists.indexOf(a?.identifier) -
                  productLists.indexOf(b?.identifier)
                )
              } else {
                return productsToRender
              }
            })
          }
        }
        let itemsToRender = sortMarkets(productsToRender, productLists)

        if (isToEnableAWSRecommendation) {
          itemsToRender = builder.sortRecommendedItems({
            products: productsToRender,
            productIds: catalogResponseFromAWS?.awsRecommendations?.productIds,
          })
          // top 10 items needs to be shown after all the filtering
          itemsToRender = itemsToRender.slice(0, 10)
        }
        setProductDetails(itemsToRender)
        // setProductDetails(sortMarkets(productsToRender, productLists))
      } else {
        return <div className="no-products">{data?.productsNotAvailable}</div>
      }
    } else {
      return <div className="no-products">{data?.productsNotAvailable}</div>
    }
  }, [props.activeTab])

  const copyAndFilter = response => {
    const products = response?.map(product => product) || []
    const productsToRender = products.filter(product => {
      const sku = product?.sku?.[0]
      let availableChannels = sku?.properties?.availableChannels || ''

      const productPrice = sku?.prices?.[0]?.price
      const isInventoryAvailable = ['in stock', 'backorder'].includes(
        sku?.inventory?.toLowerCase()
      )
      const isExclusive = sku?.properties?.isExclusive == 'true' || ''

      const productStatusSellable =
        sku?.properties?.productStatus?.toLowerCase() == 'sellable'
      const isWebChannel = availableChannels?.includes('web')

      const isDangerousGoods = sku?.properties?.dangerousGoods === 'true'
      // product?.properties?.dangerousGoods === 'true'

      const productName = product?.properties?.name || ''

      const isToShowItem =
        productPrice &&
        isInventoryAvailable &&
        !isExclusive &&
        productStatusSellable &&
        isWebChannel &&
        !isDangerousGoods

      console.log({
        section: 'MPP',
        productId: product?.identifier,
        productPrice,
        isInventoryAvailable,
        isExclusive,
        productStatusSellable,
        isWebChannel,
        isDangerousGoods,
        isToShowItem,
        productName,
      })

      return isToShowItem

      // return (
      //   product?.sku?.[0]?.prices?.[0]?.price &&
      //   ['in stock', 'backorder'].includes(
      //     product?.sku?.[0]?.inventory?.toLowerCase()
      //   ) &&
      //   product?.sku?.[0]?.properties?.isExclusive != 'true' &&
      //   product?.sku?.[0]?.properties?.productStatus?.toLowerCase() ==
      //     'sellable' &&
      //   availableChannels !== '' &&
      //   availableChannels.includes('web')
      // )
    })
    return productsToRender
  }

  const addToCart = async (skuInfo = {}, homePageAddToCart) => {
    const isBackOrdered =
      skuInfo?.sku?.[0]?.inventoryProperties?.backOrdered || ''
    return await cartContainer.addToCart({
      cartArray: [
        {
          productId: skuInfo.identifier,
          quantity: 1,
          skuId: skuInfo.sku?.[0]?.identifier,
          slug: skuInfo.properties?.slug,
          isBackOrdered: isBackOrdered,
        },
      ],
      homePageAddToCart,
    })
  }

  const getDataModal = useCallback(
    (dataKey, elem = {}) => {
      switch (dataKey) {
        case TITLE_DETAIL:
          return {
            featuredTitle: props.data?.featured_title || '',
            fontColor: props?.data?.font_color_white || '',
            title:
              props.data?.equinox_link?.title || props.data?.link?.title || '',
            id:
              props?.data?.main_title?.toLowerCase()?.replace(/ /g, '-') || '',
            link: renderLinkCheck(
              props.data?.equinox_link?.href || props.data?.link?.href || ''
            ),
            productsNotAvailable: i18nTranslate(
              'product.productsNotAvailable',
              'Products Currently Unavailable',
              {
                nameSpace: 'ssr-resource',
              }
            ),
            viewAllLink: renderLinkCheck(
              props.data?.equinox_view_all_link?.href ||
                props.data?.view_all_link?.href ||
                ''
            ),
            viewAllTitle:
              props.data?.equinox_view_all_link?.title ||
              props.data?.view_all_link?.title ||
              '',
          }
        case PRODUCT_DETAIL:
          const svPrice = parseFloat(elem.sku?.[0]?.priceFacets?.PV || 0)
          return {
            productOrSlugId: elem.properties?.slug || elem.identifier,
            title: elem.properties?.name || '',
            division:
              elem?.sku?.[0]?.properties?.division ||
              elem?.properties?.division ||
              '',
            name: elem.properties?.name || '',
            price:
              elem.sku?.[0]?.prices?.[0]?.price &&
              `${
                elem.sku?.[0]?.prices?.[0]?.currencySymbol || '$'
              }${elem.sku?.[0]?.prices?.[0]?.price.toFixed(2)}`,
            dangerousGoods: elem?.sku[0]?.properties?.dangerousGoods === 'true',
            // elem?.properties?.dangerousGoods === 'true',
            priceNotAvailable: i18nTranslate(
              'product.priceNotAvailable',
              'Price not available',
              {
                nameSpace: 'ssr-resource',
              }
            ),
            ...(enableLiveEvent
              ? {
                  svPrice:
                    svPrice > 0 &&
                    `${
                      elem.sku?.[0]?.prices?.[0]?.currencySymbol || '$'
                    }${svPrice.toFixed(2)}`,
                }
              : {}),
          }

        default:
          break
      }
    },
    [
      productDetails,
      props.data?.featured_title,
      props.data?.equinox_link?.title || props.data?.link?.title,
      props.data?.equinox_link?.href || props.data?.link?.href,
      props.data?.equinox_view_all_link?.href ||
        props.data?.view_all_link?.href,
      props.data?.equinox_view_all_link?.title ||
        props.data?.view_all_link?.title,
    ]
  )

  const getStyleGuides = useCallback(
    (dataKey, elem = {}) => {
      // const sku = elem?.sku?.find(sku => sku?.default == true)
      // As per requirement we need to use 1st SKU instead of the default
      let productImageURL = getImageFromProperty(elem?.sku?.[0]?.properties)

      // If we don't have image in the 1st SKU then we need to check all the skus
      if (!productImageURL && elem?.sku) {
        const skus = elem?.sku
        for (let i = 1; i < skus.length; i++) {
          productImageURL = getImageFromProperty(elem?.sku?.[i]?.properties)
          if (productImageURL) {
            break
          }
        }
      }

      if (!productImageURL) {
        productImageURL = getImageFromProperty(elem.properties)
      }

      switch (dataKey) {
        case TITLE_DETAIL:
          return {
            background: `url(${
              getBynderImage(
                { bynderImage: props.data?.bynder_featured_background?.[0] },
                true
              ) || imagePlaceholder
            })`,
            imageURL1:
              'https://www.nuskin.com/static/nu-home/assets/img/gray-cream.c348e4d7.png',
            imageAlt1: 'gray cream',
            imageURL2:
              'https://www.nuskin.com/static/nu-home/assets/img/white-cream.5a4c116c.png',
            imageAlt2: 'white cream',
            dataFeaturedTitle:
              props.data?.featured_title ||
              getBynderAltText(props.data?.bynder_featured_background?.[0]),
            dataAltResolution:
              props.data?.featured_title !== '' &&
              props.data?.featured_title !== null
                ? 'cs'
                : getBynderAltTextSrc(
                    props.data?.bynder_featured_background?.[0]
                  ),
          }
        case PRODUCT_DETAIL:
          return {
            productImageURL: productImageURL,
            productImageAlt: elem.properties?.name || 'product image',
          }

        default:
          break
      }
    },
    [productDetails, props.data?.featured_background?.url]
  )
  const getPriceWithCurrency = (priceValue = 0) => {
    const formattedPrice = currencyFormat({
      price: priceValue,
    })
    return formattedPrice
  }
  const renderProduct = useCallback(
    (elem, index) => {
      const data = getDataModal(PRODUCT_DETAIL, elem)
      const formattedPrice = data?.price?.replace?.(/[$,]/g, '') || '' // Removes $ and ,
      const formattedProductPrice = getPriceWithCurrency(formattedPrice)
      const styles = getStyleGuides(PRODUCT_DETAIL, elem)
      // const locale = APPConfig?.getActiveAppConfig()?.defaultLocale
      // const language = locale?.split('_')?.reverse()?.join('/')?.toLowerCase()
      const handleAddClick = e => {
        e.stopPropagation()
        e.preventDefault()
        if (e.code === 'Enter') {
          addToCart(elem, false)
        }
        if (e.type === 'click') {
          addToCart(elem, true)
        }
      }
      const defaultSku = catalogDeps.getDefaultSelectedSku(elem)
      let link = `${pageNames.product}/${data?.productOrSlugId}`
      const skuId = defaultSku?.[0]?.identifier || ''
      const skuSlug = defaultSku?.[0]?.properties?.slug || ''
      const hasMoreThanOneSku = (elem?.sku?.length || 1) > 1
      const variantParameter = skuSlug || skuId || ''
      /* @variant_autoselect */
      if (hasMoreThanOneSku && variantParameter) {
        link += `?variant=${variantParameter}`
      }
      const isReatailOrPreffered =
        customerContainer?.profileResponse?.accounts?.accountType ===
          'Retail Customer' ||
        customerContainer?.profileResponse?.accounts?.accountType ===
          'Preferred Customer/Member'
      return (
        <Card key={`productSec-${index}`}>
          <Card.Body>
            <PageLink
              data-testid="link"
              linkInfo={{
                isThirdPartyPage: false,
                link: link,
              }}
              onClick={e => isDragging && e.preventDefault()}
              // href={`/${language}/product/${data?.productOrSlugId}`}
              className="text-decoration-none">
              <div className="product_image">
                {/* <Card.Img
                  className="ProductImg"
                  variant="top"
                  src={styles?.productImageURL || imagePlaceholder}
                  alt={styles?.productImageAlt}
                  title={data?.title}
                /> */}
                <ImageComponent
                  testid="ProductImg"
                  className="ProductImg"
                  src={styles?.productImageURL || imagePlaceholder}
                  alt={styles?.productImageAlt}
                />
              </div>

              {/* <Card.Subtitle className="subhead-s primaryBrand limit-title-height">
                {data?.division}
              </Card.Subtitle> */}
              <Card.Title>
                <h2 className="heading-3-bold limit-title-height">
                  {data?.name}
                </h2>
              </Card.Title>

              <Card.Text>
                {data?.price ? (
                  <span className="content paragraph-m-bold d-block">
                    {formattedProductPrice}
                  </span>
                ) : (
                  <span className="price-unavailable">
                    {data?.priceNotAvailable}
                  </span>
                )}
                {enableLiveEvent &&
                  customerContainer.isRegisterUser &&
                  data?.svPrice &&
                  !isReatailOrPreffered && (
                    <span className="event-product-sv-price">
                      {i18nTranslate('pdp.svprice', 'SV:') +
                        ' ' +
                        data?.svPrice}
                    </span>
                  )}
              </Card.Text>

              {!isToEnableAWSRecommendation && (
                <Button
                  className="pop-add-btn d-flex cta text-uppercase"
                  onKeyPress={e => {
                    handleAddClick(e)
                  }}
                  disabled={data?.dangerousGoods}
                  onClick={e => {
                    handleAddClick(e)
                  }}>
                  <CartIconNew style={{ fontSize: '24px' }} />
                  {i18nTranslate('pdp.addToCart', 'Add To Cart', {
                    nameSpace: 'ssr-resource',
                  })}
                </Button>
              )}
            </PageLink>
            {data?.dangerousGoods && (
              <span className="text-bundle-danger">
                {i18nTranslate(
                  'pdp.dangerousGoodsError',
                  'Dangerous Goods cannot be added to Cart or Subscribed',
                  {
                    nameSpace: 'ssr-resource',
                  }
                )}
              </span>
            )}
          </Card.Body>
        </Card>
      )
    },
    [productDetails, props?.data, isDragging]
  )
  const refSlider = useRef()
  let onSwipe = () => {
    if (refSlider) {
      refSlider.current.innerSlider.clickable = true
    }
  }
  const renderArrows = () => {
    return (
      <div className="slider-arrow">
        <div
          className="arrow-btn prev"
          onClick={() => refSlider?.current?.slickPrev()}>
          <NuskinArrowLeft />
        </div>
        <div
          className="arrow-btn next"
          onClick={() => refSlider?.current?.slickNext()}>
          <NuskinArrowRight />
        </div>
      </div>
    )
  }

  const TITLE_DETAIL = 'TITLE_DETAIL'
  const PRODUCT_DETAIL = 'PRODUCT_DETAIL'
  const PRODUCT_ID = 'product-section'

  let sliderSettings = {}

  // Slider settings for Live Event
  if (enableLiveEvent) {
    sliderSettings = {
      dots: true,
      infinite: false,
      onSwipe: () => onSwipe(),
      speed: 200,
      swipeToSlide: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      afterChange: () => handleGTMCarouselClickEvent(),
      onInit: () => handleTabIndex(),
      arrows: false,
      beforeChange: () => {
        setIsDragging(true)
      },
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            swipe: true,
            centerPadding: '50px',
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            swipe: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            swipe: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '100px',
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '50px',
          },
        },
      ],
    }
  } else {
    sliderSettings = {
      dots: true,
      appendDots: dots => getCustomDots(dots),
      infinite: true,
      onSwipe: () => onSwipe(),
      speed: 200,
      swipeToSlide: true,
      slidesToShow:
        isToEnableAWSRecommendation || props?.isToShowSingleSection ? 4 : 2,
      slidesToScroll: 1,
      afterChange: () => handleGTMCarouselClickEvent(),
      onInit: () => handleTabIndex(),
      arrows: false,
      beforeChange: () => {
        setIsDragging(true)
      },
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow:
              isToEnableAWSRecommendation || props?.isToShowSingleSection
                ? 4
                : 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            swipe: true,
          },
        },
        // {
        //   breakpoint: 767,
        //   settings: {
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     centerMode: true,
        //     centerPadding: '100px',
        //   },
        // },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '50px',
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '100px',
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '50px',
          },
        },
      ],
    }
  }

  const settings = getSliderSettings()

  useEffect(() => {
    getProducts()
  }, [props.activeTab])

  const RenderPlaceHolderComp = () => {
    return (
      <ProductsPlaceHolder
        columnType={{
          lg:
            isToEnableAWSRecommendation || props?.isToShowSingleSection
              ? '3'
              : '6',
          md:
            isToEnableAWSRecommendation || props?.isToShowSingleSection
              ? '4'
              : '1',
        }}
        products={products}
      />
    )
    // return (
    //   <div className="mpp-placeholder">
    //     {products.length > 0 &&
    //       products.map((product, i) => (
    //         <Col lg={6} md={1} key={i}>
    //           <div className="product-card">
    //             <div className="image"></div>
    //             <div className="content">
    //               <h2></h2>
    //               <p></p>
    //             </div>
    //           </div>
    //         </Col>
    //       ))}
    //   </div>
    // )
  }

  const styles = getStyleGuides(TITLE_DETAIL)
  const data = getDataModal(TITLE_DETAIL)

  if (enableLiveEvent && productDetails.length > 0) {
    props.showFeaturedProducts()
  }

  return (
    <div
      className={`popular-section ${
        isToEnableAWSRecommendation ? 'recommended-products' : ''
      }`}
      id={PRODUCT_ID}>
      <div></div>
      <Container>
        <div className="popular-section-inner position-relative">
          <Image
            src={styles?.imageURL1}
            alt={styles?.imageAlt1}
            className="product-image-bg-left"
          />
          <Image
            src={styles?.imageURL2}
            alt={styles?.imageAlt2}
            className="product-image-bg-right"
          />
          <Row className="pt-2">
            {!isToEnableAWSRecommendation && (
              <Col lg={5} md={6} sm={12} className="popular-section-left">
                <div
                  className="popular-sidebar"
                  style={{ background: styles?.background }}
                  role="img"
                  aria-label={styles?.dataFeaturedTitle}
                  data-alt-text-resolution={styles?.dataAltResolution}>
                  <div className="populat-text">
                    <h3
                      className="heading heading-1"
                      style={{
                        ...(data?.fontColor
                          ? { color: '#fff' }
                          : { color: '#252525' }),
                      }}>
                      {data?.featuredTitle}
                    </h3>
                    <PageLink
                      data-testid="link"
                      linkInfo={data?.link}
                      fontToggle={data?.fontColor}
                      componentName="homepage_product"
                      className="underline1 d-flex paragraph-m">
                      <span>{data?.title}</span>
                      <ArrowIconNew className="product-arrow-icon pl-2" />
                    </PageLink>
                  </div>
                </div>
              </Col>
            )}
            <Col
              lg={
                isToEnableAWSRecommendation || props?.isToShowSingleSection
                  ? 12
                  : 7
              }
              md={
                isToEnableAWSRecommendation || props?.isToShowSingleSection
                  ? 12
                  : 6
              }
              sm={12}
              className="most-popular-slider">
              {productDetails && productDetails.length > 0 && (
                <div className="viewmain">
                  <PageLink
                    linkInfo={data?.viewAllLink}
                    className="text-decoration-none d-inline-block">
                    <h6 className="viewsec paragraph-m">
                      {data?.viewAllTitle}
                    </h6>
                  </PageLink>
                </div>
              )}
              <div>
                {productDetails?.length > 2 && renderArrows()}
                {catalogContainer.shouldShowMPPPlaceholder && (
                  <RenderPlaceHolderComp />
                )}
                {productDetails?.length > 0 && (
                  <Slider
                    {...settings}
                    dots={true}
                    ref={slider => (refSlider.current = slider)}>
                    {productDetails.map(renderProduct)}
                  </Slider>
                )}
              </div>
              {productDetails && productDetails.length > 0 && (
                <div className="viewmain-mobile">
                  <PageLink
                    linkInfo={data?.viewAllLink}
                    className="text-decoration-none d-inline-block">
                    <h6 className="viewsec paragraph-m">
                      {data?.viewAllTitle}
                    </h6>
                  </PageLink>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Container>
      {/* <>
        <Button variant="primary" onClick={() => setShowModal(true)}>
          Verify Phone Number
        </Button>
      </>

      <PhoneVerification
        countryCode={'+1'}
        modalData={showModalData}
        showModal={showModal}
        onClose={onClose}
      /> */}
    </div>
  )
}

export { ProductSection }
export default ProductSection
