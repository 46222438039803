import * as React from 'react'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Container, Col, Card } from 'react-bootstrap'
import { pageNames } from 'src/routes/pathParams'
import { setResponsiveValues, tryJSONParse } from 'src/utils'
import { data } from './fixture'
import './styles.scss'

function calculateFlexPercentage(tileGridProps, colSpan) {
  const {
    desktopColSpan = 12,
    tabletColSpan = 12,
    mobileColSpan = 12,
  } = tileGridProps?.properties || {}

  return (
    (100 / setResponsiveValues(desktopColSpan, tabletColSpan, mobileColSpan)) *
    colSpan
  )
}

@observer
class HomeBanner extends React.Component {
  static defaultProps = {
    className: '',
    dataTestId: '',
    items: data.tileGridItemProperties,
    properties: {},
  }

  renderGrid = (props, tileGridProps) => {
    const { src, alternateText, url, colSpan = 4, spacing } = props?.item || {}
    const flexPercentage = calculateFlexPercentage(tileGridProps, colSpan)
    const link = url.match(/http(s*):\/\//)
      ? url
      : `${pageNames.catalog}/${url}`
    const navigationLink = !url ? 'home-banner-hide-event' : ''
    return (
      <Col
        style={{ flex: '0 0 ' + flexPercentage + '%', padding: spacing }}
        key={props.index}
        className="card-image-wrapper">
        <Link to={link} className={navigationLink}>
          <Card.Img
            variant="top"
            className={'h-100 rounded-0'}
            src={src}
            alt={alternateText}
          />
        </Link>
      </Col>
    )
  }

  render() {
    const tileGridItemProperties = this.props?.items || []
    const { className = '', dataTestId = '' } = this.props

    const wrapProps = this.props?.properties?.shouldWrap || 'true'

    const { backgroundColor, fullWidth } = this.props?.properties || ''

    const wrapPropsClassName =
      wrapProps === 'true' ? 'wrapClass' : 'nowrapClass'
    const promoGridView =
      tileGridItemProperties &&
      tileGridItemProperties.length > 0 &&
      tileGridItemProperties.map((item, index) => {
        return this.renderGrid({ item, index }, this.props)
      })

    const widgetName = this.props?.widgetName

    return (
      <section
        className={`home-banner position-relative ${className} ${widgetName}`}
        data-testid={dataTestId}>
        <Container
          className="px-0"
          fluid={tryJSONParse(fullWidth)}
          style={{
            backgroundColor,
          }}>
          <div
            className={`home-banner-card-wrapper background-transparent position-relative d-flex ${wrapPropsClassName}`}>
            {promoGridView}
          </div>
        </Container>
      </section>
    )
  }
}

export { HomeBanner }
export default HomeBanner
