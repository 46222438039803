import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import Slider from 'react-slick'
import TagManager from 'react-gtm-module'
import { Card, Container } from 'react-bootstrap'
import ContentStack from 'src/api/contentStack'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import './styles.css'
import { ImageComponent } from 'src/views/components'
import {
  getBynderImage,
  getBynderAltText,
  getBynderAltTextSrc,
} from 'src/utils'

@observer
class FeaturedinSection extends React.Component {
  @observable featureSection = []

  constructor(props) {
    super(props)
    this.slider = React.createRef(null)
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = () => {
    const uid = this.props?.data?.disclaimer?.[0]?.uid || ''

    ContentStack.getReference('featured_in', uid, this.props).then(response => {
      if (response?.blocks) {
        this.featureSection = response?.blocks
      }
    })
  }

  setSliderRef = slider => {
    this.slider.current = slider
  }

  handleGTMCarouselClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'carouselClicked',
        pagePath: '/',
        pageTitle: 'Home',
        section: 'Featured In',
        platform: 'equinox',
      },
    })
  }

  getStyleGuides = () => {
    return {
      dots: true,
      appendDots: dots => this.getCustomDots(dots),
      infinite: true,
      speed: 200,
      swipeToSlide: true,
      slidesToShow: 4,
      // slidesToScroll: 1,
      arrows: false,
      afterChange: this.handleGTMCarouselClick,
      beforeChange: (prev, next) => {
        this.setState({ currentSlide: next })
      },
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            // arrows: false,
            dots: true,
          },
        },
        {
          breakpoint: 920,
          settings: {
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerPadding: '60px',
          },
        },
        {
          breakpoint: 576,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            centerMode: true,
            centerPadding: '98px',
          },
        },
        {
          breakpoint: 400,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            centerMode: true,
            centerPadding: '90px',
          },
        },
        {
          breakpoint: 350,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            centerMode: true,
            centerPadding: '40px',
          },
        },
      ],
    }
  }

  getCustomDots = dots => {
    const customDots = []
    this.featureSection?.forEach((block, index) => {
      this.slider?.current?.slickGoTo?.(0)
      customDots.push(
        <li key={`productDot_${index}`} {...dots?.[index]?.props}>
          <button
            aria-label={`featured image ${index + 1} of ${
              this.featureSection?.length
            }`}
            onClick={dots?.[index]?.props?.children?.props?.onClick}>
            {index + 1}
          </button>
        </li>
      )
    })

    return <ul className="slick-dots">{customDots}</ul>
  }

  renderFeatureCard = (featuredIn, index) => {
    const data = {
      imageURL:
        getBynderImage({
          bynderImage: featuredIn?.featuredin?.bynder_image?.[0],
        }) || imagePlaceholder,
      alt:
        featuredIn?.featuredin?.image_description ||
        getBynderAltText(featuredIn?.featuredin?.bynder_image?.[0]) ||
        '',
      title: featuredIn?.featuredin?.featured_title || '',
      imageAltTextResolution:
        featuredIn?.featuredin?.image_description !== '' &&
        featuredIn?.featuredin?.image_description !== null
          ? 'cs'
          : getBynderAltTextSrc(featuredIn?.featuredin?.bynder_image?.[0]),
    }
    return (
      <Card key={`featuredInsec-${index}`} className="featuredinsec">
        <div className="featured-box">
          <ImageComponent
            className="featuredimg"
            variant="top"
            src={data?.imageURL || ''}
            alt={data?.alt || ''}
            title={data?.title || ''}
            dataAltSrc={data?.imageAltTextResolution}
          />
          {/* <Card.Img
            className="featuredimg"
            variant="top"
            src={data?.imageURL}
            alt={data?.alt}
            title={data.title}
          /> */}
        </div>
      </Card>
    )
  }

  render() {
    const settings = this.getStyleGuides()

    return (
      <div className="featuredsec">
        <Container className="featured-container">
          <Slider
            {...settings}
            dots={true}
            ref={slider => (this.slider.current = slider)}>
            {this?.featureSection?.map(this.renderFeatureCard)}
          </Slider>
        </Container>
      </div>
    )
  }
}
export { FeaturedinSection }
export default FeaturedinSection
