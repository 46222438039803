import React from 'react'
import HighLighterOne from 'src/views/studioComponents/HighLighterOne'
import HighLighterTwo from 'src/views/studioComponents/HighLighterTwo'

class HighLighterMain extends React.Component {
  getDataModal = () => {
    return {
      imagePosition: this.props.data?.image_position || '',
      data: this.props.data || {},
    }
  }

  render() {
    const { imagePosition, data } = this.getDataModal()

    return (
      <>
        {imagePosition === 'left' && <HighLighterOne data={data} />}
        {imagePosition === 'right' && <HighLighterTwo data={data} />}
      </>
    )
  }
}

export { HighLighterMain }
export default HighLighterMain
