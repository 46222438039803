import React from 'react'
import liveEventTrmeBanner from 'src/images/live-event-trme-banner.png'
import liveEventTrmeGradient from 'src/images/live-event-trme-gradient.png'
import { getBynderImage, renderLinkCheck } from 'src/utils'
import { PageLink } from 'src/views/components'

class TrMeBanner extends React.Component {
  render() {
    const entry = this.props.entry || {}
    const bigTitle = entry?.big_title || 'TrMe Go Promo'
    const description =
      entry?.description || 'Vivamus convallis congue dolor, vel commodo libero'
    const imagePosition = entry?.image_position || 'left'
    const learnMoreButton = entry?.link?.title || 'Learn More'
    const linkProps = renderLinkCheck(
      entry?.equinox_link?.href || entry?.link?.href || '',
      this.props?.staticContext?.originalUrl
    )
    const bynderImage = getBynderImage({
      bynderImage: entry?.bynder_image?.[0],
      imageQueryParam: '',
      defaultType: 'webImage',
    })

    const imageUrl = bynderImage || entry?.image?.url || liveEventTrmeBanner

    return (
      <div className="event-trme-container">
        <div className="event-trme-left">
          <img src={imageUrl} className="event-trme-banner-img" />
        </div>
        <div className="event-trme-right">
          {/* <img
            src={liveEventTrmeGradient}
            className="event-trme-gradient-img"
          /> */}
          <div className="event-trme-title-container">
            <div className="event-trme-primary-title">{bigTitle}</div>
            <div className="event-trme-secondary-title">{description}</div>
            <div className="event-trme-btn-container">
              <PageLink linkInfo={linkProps} className="event-trme-btn-learn">
                {learnMoreButton}
              </PageLink>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export { TrMeBanner }
