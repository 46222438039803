import React from 'react'
import heroDesktopBackground from 'src/images/live-event-hero-desktop.png'
import { i18nTranslate } from 'src/utils'
import { getBynderImage, renderLinkCheck } from 'src/utils'
import { PageLink } from 'src/views/components'

class HeroBanner extends React.Component {
  render() {
    let backgroundImage = heroDesktopBackground
    const heroBlock = this.props?.entry?.hero[0]?.hero_block
    const title = heroBlock?.big_title || 'Welcome to'
    const description = heroBlock?.big_title || 'L!VE 2024'

    if (heroBlock) {
      backgroundImage = getBynderImage({
        bynderImage: heroBlock?.bynder_background_image?.[0],
        imageQueryParam: '',
        defaultType: 'transformBaseUrl',
      })
    }

    const linkProps = renderLinkCheck(
      heroBlock?.equinox_link?.href || heroBlock?.link?.href || '',
      this.props?.staticContext?.originalUrl
    )

    const buttonTitle =
      heroBlock?.equinox_link?.title || heroBlock?.link?.title || ''

    return (
      <div className="event-hero-container">
        {/* <img className="event-hero-desktop-img" src={backgroundImage} /> */}
        <div
          className="event-hero-content"
          style={{ backgroundImage: `url(${backgroundImage})` }}>
          <div className="event-hero-content-left">
            <div className="event-hero-title">
              <div className="event-hero-title-1">{title}</div>
              <div className="event-hero-title-2">{description}</div>
            </div>
            <div className="event-hero-btn-container">
              <PageLink className="event-hero-btn-shop" linkInfo={linkProps}>
                {buttonTitle}
              </PageLink>
            </div>
          </div>
          {/* <div className="event-hero-content-right">Right svg</div> */}
        </div>
      </div>
    )
  }
}

export { HeroBanner }
