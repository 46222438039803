const data = {
  tileGridItemProperties: [
    {
      src: '//raderain-cdn.sirv.com/T-Giant/Promos/Promo_790x790_Womens.png',
      url: 'Women',
      alternateText: '',
      description: '',
    },
    {
      src: '//raderain-cdn.sirv.com/T-Giant/Promos/Promo_790x790_BandShirts.png',
      url: 'Women_Undershirts',
    },

    {
      src: '//raderain-cdn.sirv.com/T-Giant/Promos/Promo_790x790_Kids.png',
      url: 'Women_Workwear',
    },
  ],
}

export { data }
