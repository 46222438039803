import React from 'react'
import liveEventOceanBanner from 'src/images/live-event-ocean-banner.png'
import { getBynderImage, renderLinkCheck } from 'src/utils'
import { PageLink } from 'src/views/components'

class OceanBanner extends React.Component {
  render() {
    const entry = this.props.entry || {}
    const bigTitle = entry?.big_title || 'TrMe Go Promo'
    const description =
      entry?.description || 'Vivamus convallis congue dolor, vel commodo libero'
    const imagePosition = entry?.image_position || 'left'
    const learnMoreButton = entry?.link?.title || 'Learn More'
    const linkProps = renderLinkCheck(
      entry?.equinox_link?.href || entry?.link?.href || '',
      this.props?.staticContext?.originalUrl
    )

    const bynderImage = getBynderImage({
      bynderImage: entry?.bynder_image?.[0],
      imageQueryParam: '',
      defaultType: 'webImage',
    })
    const imageUrl = bynderImage || entry?.image?.url || liveEventOceanBanner

    return (
      <div className="event-ocean-container">
        <div className="event-ocean-left">
          <div className="event-ocean-primary-title">{bigTitle}</div>
          <div className="event-ocean-secondary-title">{description}</div>
          <div className="event-ocean-btn-container">
            <PageLink linkInfo={linkProps} className="event-ocean-btn-learn">
              {learnMoreButton}
            </PageLink>
          </div>
        </div>
        <div className="event-ocean-right">
          <img src={imageUrl} className="event-ocean-banner-img" />
        </div>
      </div>
    )
  }
}

export { OceanBanner }
