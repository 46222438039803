import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ContentStack from 'src/api/contentStack'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import './styles.scss'

@observer
class Routines extends React.Component {
  @observable routinesSection = []
  @observable routineSteps = []
  @observable routineImages = []

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = () => {
    const uid = this.props?.data?.disclaimer?.[0]?.uid || ''

    ContentStack.getReference('influencer_block', uid, this.props).then(
      response => {
        this.routinesSection = response || []
        this.routineSteps = response?.steps || []
        this.routineImages = response?.images || []
      }
    )
  }

  getStyleGuides = () => {
    return {
      background: 'url(/static/nu-home/assets/img/section-003-bg.6034775c.jpg)',
    }
  }

  render() {
    const data = {
      image0:
        this.state.routineImages?.[0]?.image?.image?.url || imagePlaceholder,
      image1:
        this.state.routineImages?.[1]?.image?.image?.url || imagePlaceholder,
      title: this.state.routinesSection?.title || '',
      subtitle: this.state.routinesSection?.subtitle || '',
      link: this.state.routinesSection?.link?.title || '',
    }
    const styles = this.getStyleGuides()
    const steps = this.state.routineSteps.map((elem, index) => {
      return (
        <li key={index}>
          <h5 className="h5-medium">{elem['step']['text']}</h5>
        </li>
      )
    })
    return (
      <section
        className="routines-section position-relative w-100 d-flex flex-column"
        style={styles?.background}>
        <div className="routines-display w-100 mx-auto d-flex flex-column flex-md-row flex-wrap align-items-start align-items-lg-center">
          <div className="routines-display-left position-relative">
            <div className="routines-main-img">
              <Image src={data?.image0} />
            </div>
            <div className="routines-img-1">
              <Image src={data?.image1} />
            </div>
            <div className="routines-img-2">
              <img
                src="/static/nu-home/assets/img/003-thumb2.71503879.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="routines-display-right">
            <div className="routines-content-box">
              <h2>{data?.title}</h2>
              <h5 className="h5-medium">{data?.subtitle}</h5>
              <ul>{steps}</ul>
              <br />
              <Link to="">{data?.link}</Link>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export { Routines }
export default Routines
