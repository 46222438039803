import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import Slider from 'react-slick'
import TagManager from 'react-gtm-module'
import { Card, Container } from 'react-bootstrap'
import ContentStack from 'src/api/contentStack'
import { ImageComponent } from 'src/views/components'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import {
  getBynderImage,
  getBynderAltText,
  getBynderAltTextSrc,
} from 'src/utils'

import './styles.css'

@observer
class AwardsSection extends React.Component {
  @observable awardsSection = []

  constructor(props) {
    super(props)
    this.slider = React.createRef(null)
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate() {
    // if (this.slider.current) {
    //   this.slider.current.slickGoTo(0, true)
    // }
  }

  fetchData = () => {
    const uid = this.props?.data?.awards?.[0]?.uid || ''

    ContentStack.getReference('awards', uid, this.props).then(response => {
      this.awardsSection = response?.award_references || []
    })
  }

  setSliderRef = slider => {
    this.slider.current = slider
  }

  getDataModal = (post = {}) => {
    return {
      imageURL:
        getBynderImage({ bynderImage: post?.bynder_image?.[0] }) ||
        imagePlaceholder,
      alt:
        post?.description ||
        getBynderAltText(post?.bynder_image?.[0]) ||
        'Award Image',
      title: post?.name || '',
      description: post?.description || '',
      source: post?.award_source || '',
      imageAltTextResolution:
        post?.description !== '' && post?.description !== null
          ? 'cs'
          : getBynderAltTextSrc(post?.bynder_image?.[0]),
    }
  }

  handleGTMCarouselClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'carouselClicked',
        pagePath: '/',
        pageTitle: 'Home',
        section: 'Awards and Recognition',
        platform: 'equinox',
      },
    })
  }

  getStyleGuides = () => {
    return {
      dots: true,
      appendDots: dots => this.getCustomDots(dots),
      infinite: true,
      speed: 200,
      swipeToSlide: true,
      slidesToShow: 6,
      arrows: false,
      centerMode: false,
      afterChange: this.handleGTMCarouselClick,
      beforeChange: (prev, next) => {
        this.setState({ currentSlide: next })
      },
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            arrows: false,
            dots: true,
            centerMode: true,
            centerPadding: '125px',
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '90px',
          },
        },
        {
          breakpoint: 767,
          settings: {
            arrows: false,
            slidesToShow: 1,
            infinite: true,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '185px',
          },
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '150px',
          },
        },
        {
          breakpoint: 575,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            centerMode: true,
            centerPadding: '120px',
          },
        },
        {
          breakpoint: 420,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            centerMode: true,
            centerPadding: '75px',
          },
        },
      ],
    }
  }

  getCustomDots = dots => {
    const customDots = []
    this.awardsSection?.forEach((block, index) => {
      this.slider?.current?.slickGoTo?.(0)
      customDots.push(
        <li key={`productDot_${index}`} {...dots?.[index]?.props}>
          <button
            aria-label={`award image ${index + 1} of ${
              this.awardsSection?.length
            }`}
            onClick={dots?.[index]?.props?.children?.props?.onClick}>
            {index + 1}
          </button>
        </li>
      )
    })
    return <ul className="slick-dots">{customDots}</ul>
  }

  renderAward = (post, index) => {
    const data = this.getDataModal(post)
    return (
      <Card key={`awardsSec-${index}`}>
        <div className="awardsec">
          <div className="awards-box">
            {/* <Card.Img
              className="awardimg"
              variant="top"
              src={data?.imageURL}
              alt={data?.alt}
            /> */}
            <ImageComponent
              testid="awardimg"
              className="awardimg"
              src={data?.imageURL}
              alt={data?.alt}
              title={data?.title}
              dataAltSrc={data?.imageAltTextResolution}
            />
          </div>
          <Card.Title className="heading-5"> {data?.title}</Card.Title>
          <Card.Text className="award-text paragraph-m award-text-mobile">
            {data?.description}
          </Card.Text>
          <Card.Text className="award-sub-text paragraph-xs-italic">
            {data?.source}{' '}
          </Card.Text>
        </div>
      </Card>
    )
  }

  render() {
    const settings = this.getStyleGuides()
    return (
      <div className="awardssec">
        <Container className="award-container">
          <Slider
            {...settings}
            dots={true}
            ref={slider => (this.slider.current = slider)}>
            {this.awardsSection.map(this.renderAward)}
          </Slider>
        </Container>
      </div>
    )
  }
}
export { AwardsSection }
export default AwardsSection
