import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { Tabs, Tab, Container } from 'react-bootstrap'
import { ProductCarousel } from '../ProductCarousel'
import './styles.scss'

@observer
class TabbedCarousel extends React.Component {
  @observable carouselData = {}
  static defaultProps = {
    items: [],
    properties: {},
  }

  renderTabbedItems = (tabItem, index) => {
    const { properties } = this.props

    return (
      <Tab
        tabClassName="tabbed-carousel"
        title={tabItem.categoryName}
        eventKey={`tab-${index}`}
        key={tabItem.categoryName}>
        <ProductCarousel
          properties={{ ...properties, categoryId: tabItem.categoryId }}
        />
      </Tab>
    )
  }
  render() {
    const { items } = this.props
    const itemList = items.map(this.renderTabbedItems)
    const widgetName = this.props?.widgetName

    if (itemList.length > 0) {
      return (
        <Container
          className={`px-2 pt-2 px-md-0 px-md-0 tabbed-prod-wrapper ${widgetName}`}
          data-testid="qa-recommended-product-container">
          <Tabs
            defaultActiveKey="tab-0"
            transition={false}
            className="border-0"
            id="tabbed-product-carousel">
            {itemList}
          </Tabs>
        </Container>
      )
    }
    return <></>
  }
}

export default TabbedCarousel
export { TabbedCarousel }
