import React from 'react'
import CountUp from 'react-countup'
import { Container, Row, Col } from 'react-bootstrap'
import { renderLinkCheck } from 'src/utils'
import './styles.scss'
import { PageLink } from 'src/views/components'

class InfoBlock extends React.PureComponent {
  TITLE_INFO = 'TITLE_INFO'
  DETAIL_INFO = 'DETAIL_INFO'

  getDataModal(dataKey, { index = 0 } = {}) {
    switch (dataKey) {
      case this.TITLE_INFO:
        return {
          bigTitle1: this.props?.data?.big_title?.substring(0, 8) || '',
          bigTitle2: this.props?.data?.big_title?.substring(9) || '',
          infoBlocks: this.props?.data?.info_block || [],
        }
      case this.DETAIL_INFO:
        const infoBlocks = this.props?.data?.info_block || []
        const infoBlock = infoBlocks?.[index]?.info || {}
        return {
          number: infoBlock?.number || 0,
          afterSymbol: infoBlock?.after_symbol || '',
          label: infoBlock?.label || '',
          href: renderLinkCheck(
            infoBlock?.equinox_link?.href || infoBlock?.link?.href
          ),
        }
      default:
        return {}
    }
  }

  renderInfoBlock = (_, index) => {
    const data = this.getDataModal(this.DETAIL_INFO, { index })
    const liParentClassName = `${index == 0 ? 'nohover' : 'hover'}`
    return (
      <li
        data-testid={`infobar-item-${index}`}
        className={liParentClassName}
        key={`info-bar-${index}`}>
        <PageLink
          data-testid="link"
          linkInfo={data?.href}
          target="_blank"
          tabIndex={index == 0 ? '-1' : '0'}>
          <h2 className="heading-1">
            <span>
              <CountUp start={0} end={data?.number} duration={2.9} />
            </span>
            {data?.afterSymbol}
          </h2>
          <h5 className="h5-medium"> {data?.label}</h5>
        </PageLink>
      </li>
    )
  }
  render() {
    const data = this.getDataModal(this.TITLE_INFO)
    return (
      <div className=" position-relative w-100 info-block-section d-flex">
        <Container>
          <Row className="info-section d-lg-flex justify-content-between">
            <Col lg="5" className="p-0 mb-2 mb-lg-0 d-inline-block">
              <h2 className="heading-1">
                {data?.bigTitle1}
                <br />
                {data?.bigTitle2}
              </h2>
            </Col>
            <Col lg="7" className="p-0">
              <ul className="d-flex p-0 m-0 mb-md-0 d-inline-block">
                {data.infoBlocks?.map(this.renderInfoBlock)}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export { InfoBlock }
export default InfoBlock
