import React from 'react'
import { Container } from 'react-bootstrap'
import { observer } from 'mobx-react'
import { tryJSONParse } from 'src/utils'
import '../../fonts/fonts.css'

const textAlign = {
  center: 'center',
  start: 'left',
  end: 'right',
}

@observer
class Text extends React.Component {
  static defaultProps = {
    properties: {
      backgroundColor: '#FFF',
      fullWidth: false,
      Align: 'center',
      className: '',
      textColor: '#000',
      spacing: 0,
      fontSize: '16px',
      text: 'Text',
      maxWidth: 'inherit',
    },
  }
  render() {
    const {
      backgroundColor,
      fullWidth,
      Align,
      className,
      textColor,
      spacing,
      fontSize = '20px',
      text,
      maxWidth,
    } = this.props?.properties || ''
    const bannerTextView =
      text === 'The Nu Skin Way' ? (
        <Container
          fluid={tryJSONParse(fullWidth)}
          className={`p-0 ${className} d-flex justify-content-center text-${textAlign[Align]}`}
          style={{
            backgroundColor,
          }}>
          <div
            className="position-relative nuskin"
            style={{
              color: textColor,
              padding: spacing,
              fontSize: '60px',
              fontWeight: 300,
              lineHeight: '80px',
              fontFamily: 'nuskin-font !important',
              width: maxWidth,
            }}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </Container>
      ) : (
        <Container
          fluid={tryJSONParse(fullWidth)}
          className={`p-0 ${className} d-flex justify-content-center text-${textAlign[Align]}`}
          style={{
            backgroundColor,
          }}>
          <div
            className="position-relative"
            style={{
              color: textColor,
              padding: spacing,
              fontSize,
              fontWeight: 'bold',
              textTransform: 'uppercase',
              fontStyle: 'italic',
              width: maxWidth,
              letterSpacing: '1.5px',
            }}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </Container>
      )
    return bannerTextView
  }
}
export { Text }
