import React from 'react'
import { Card, Container, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import i18n from 'i18next'
import { observer } from 'mobx-react'
import ReactSlick from 'react-slick'
import { GoPrimitiveDot } from 'react-icons/go'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { pageNames } from 'src/routes/pathParams'
import { setResponsiveValues, tryJSONParse } from 'src/utils'
import './styles.scss'

@observer
class CategoryGrid extends React.Component {
  static defaultProps = {
    items: [],
    properties: {
      gridGap: 10,
      desktopColSpan: 4,
      tabletColSpan: 3,
      mobileColSpan: 2,
      hasArrows: true,
      hasBullets: true,
    },
  }
  renderItem = (item, index) => {
    const { caption, src, url } = item
    let linkProperties = ''
    if (url) {
      linkProperties = url.match(/http(s*):\/\//)
        ? { href: url, target: '_blank', rel: 'noopener noreferrer' }
        : {
            as: Link,
            to: url.match(/\/[w]+\//) ? url : `${pageNames.catalog}/${url}`,
          }
    }
    return (
      <Card
        key={`card-${index}`}
        className="w-100 h-100 border-0 rounded-0 px-2 bg-transparent Categorygrid-img">
        <Nav.Link className="p-0 d-flex flex-column h-100" {...linkProperties}>
          <div className="d-flex justify-content-center align-items-center">
            <Card.Img
              src={src}
              className="w-auto mw-100 mh-100 picture"
              aria-label={caption}
            />
          </div>
          <Card.Footer className="text-dark border-0 flex-grow-1 bg-transparent text-center">
            {caption}
          </Card.Footer>
        </Nav.Link>
      </Card>
    )
  }

  getSlidesToShow = () => {
    const { desktopColSpan, tabletColSpan, mobileColSpan } =
      this.props.properties
    const colSpan = setResponsiveValues(
      desktopColSpan,
      tabletColSpan,
      mobileColSpan
    )

    try {
      const parsedValue = JSON.parse(colSpan)
      return parsedValue
    } catch (e) {
      return colSpan
    }
  }

  render() {
    const widgetName = this.props?.widgetName
    const borderRadiusType = this.props?.properties?.borderRadiusType || ''
    const gridonhovereffect = this.props?.properties?.gridonhovereffect || ''
    const { hasArrows, hasBullets, title, fullWidth } = this.props.properties
    const slidesToShow = this.getSlidesToShow()
    const ForwardIcon =
      i18n.dir() === 'rtl' ? IoIosArrowBack : IoIosArrowForward
    const BackIcon = i18n.dir() === 'rtl' ? IoIosArrowForward : IoIosArrowBack
    const Customstyle = `.${widgetName} ${borderRadiusType} .${widgetName} ${gridonhovereffect}`
    return (
      this.props.items.length > 0 && (
        <Container
          fluid={tryJSONParse(fullWidth)}
          className={`px-2 p-lg-0 p-md-0 position-relative ${widgetName} category-grid`}>
          <style>{Customstyle}</style>
          <div className="py-lg-0 pt-md-2 my-0 font-weight-bold">{title}</div>
          <ReactSlick
            arrows={hasArrows === 'true' || hasArrows === true}
            dots={hasBullets === 'true' || hasBullets === true}
            draggable={true}
            infinite={false}
            speed={500}
            slidesToShow={slidesToShow}
            slidesToScroll={slidesToShow}
            focusOnSelect={true}
            initialSlide={0}
            dotsClass="d-flex justify-content-center dots-style pt-3 px-0"
            nextArrow={<ForwardIcon size="2rem" />}
            prevArrow={<BackIcon size="2rem" />}
            customPaging={() => {
              return (
                <span className="dot-class">
                  <GoPrimitiveDot size="1.5rem" />
                </span>
              )
            }}
            className="pt-4 media-carousel">
            {this.props.items.map(this.renderItem)}
          </ReactSlick>
        </Container>
      )
    )
  }
}

export { CategoryGrid }
export default CategoryGrid
