import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import Slider from 'react-slick'
import TagManager from 'react-gtm-module'
import { Card, Row, Col } from 'react-bootstrap'
import ContentStack from 'src/api/contentStack'
import NuskinArrowRight from 'src/views/components/Icons/NuskinArrowRight'
import NuskinArrowLeft from 'src/views/components/Icons/NuskinArrowLeft'
import { renderLinkCheck, getBynderImage, getBynderAltText } from 'src/utils'
import { IS_BROWSER } from 'config/appConfig'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import './styles.css'
import { ImageComponent, PageLink } from 'src/views/components'
import { getBynderAltTextSrc } from 'src/utils'

@observer
class BrandSection extends React.Component {
  @observable brand = {}

  TITLE_DETAIL = 'TITLE_DETAIL'
  BRAND_DETAIL = 'BRAND_DETAIL'
  setTimer = null
  mailTitle = 'brandsec'

  constructor(props) {
    super(props)
    this.slider = React.createRef(null)
    this.state = { isDragging: false, isInitialSlideSet: false }
  }
  componentDidMount() {
    this.fetchData()
  }

  fetchData = () => {
    const uid = this.props?.data?.brand_slider?.[0]?.uid || ''

    ContentStack.getReference('brand_slider', uid, this.props).then(
      response => {
        console.log(`response ==>`, { response })

        if (response && response?.blocks) {
          this.brand = response || {}
          this.setState({ isDragging: false })
        }
      }
    )
  }

  // fix for ADA as child anchor tags are also included in tab navigation
  // inside child elements
  methodToReuseAdaFix = () => {
    if (IS_BROWSER) {
      let anchorTags
      this.setTimer = setTimeout(() => {
        anchorTags = document.querySelectorAll(
          `#${this.mailTitle} .slick-list a`
        )
      }, 700)

      if (anchorTags?.length > 0) {
        this.handleADA()
      } else {
        if (this.setTimer) {
          clearTimeout(this.setTimer)
        }
        this.setTimer = setTimeout(() => {
          this.handleADA()
        }, 700)
      }
    }
  }

  handleADA = () => {
    const slickLists = document.querySelectorAll(
      `#${this.mailTitle} .slick-list .slick-slide`
    )
    slickLists?.forEach(elem => {
      const isHidden = elem.getAttribute('aria-hidden') || ''
      const aTags = elem.getElementsByTagName('a')[0] || ''
      if (isHidden === 'true') {
        if (aTags) {
          aTags.setAttribute('tabindex', '-1')
        }
      } else if (isHidden === 'false') {
        if (aTags) {
          aTags.removeAttribute('tabindex')
        }
      }
    })
  }

  handleTabIndex = () => {
    this.methodToReuseAdaFix()
  }

  handleTabIndexAndGTMCarouselClick = () => {
    this.setState({ isDragging: false })
    this.methodToReuseAdaFix()
    TagManager.dataLayer({
      dataLayer: {
        event: 'carouselClicked',
        pagePath: '/',
        pageTitle: 'Home',
        section: 'Explore Some Of Our Brands',
        platform: 'equinox',
      },
    })
  }

  onSwipe = () => {
    if (this.slider?.current?.innerSlider) {
      this.slider.current.innerSlider.clickable = true
    }
    this.setState({ isDragging: true })
  }

  getDataModal = (dataKey, brandDetails = {}) => {
    switch (dataKey) {
      case this.TITLE_DETAIL:
        return {
          title:
            this.brand?.equinox_view_all_link?.title ||
            this.brand?.view_all_link?.title ||
            '',
          href: renderLinkCheck(
            this.brand?.equinox_view_all_link?.href ||
              this.brand?.view_all_link?.href ||
              ''
          ),
          brandBlocks: this.brand?.blocks || [],
        }

      case this.BRAND_DETAIL:
        return {
          href: renderLinkCheck(
            brandDetails.brand?.equinox_link?.href ||
              brandDetails.brand?.link?.href
          ),
          title:
            brandDetails?.brand?.equinox_link?.title ||
            brandDetails?.brand?.link?.title ||
            '',
        }

      default:
        return {}
    }
  }

  getStyleGuide = (brandDetails = {}) => {
    const bynderBackgroundImage = getBynderImage({
      bynderImage: brandDetails.brand?.bynder_background_image?.[0],
    })

    const bynderBrandLogo = getBynderImage({
      bynderImage: brandDetails.brand?.bynder_brand_logo?.[0],
    })

    const backGrdAltText =
      brandDetails.brand?.equinox_link?.title ||
      getBynderAltText(brandDetails.brand?.bynder_background_image?.[0]) ||
      'brand logo'

    const logoAltText =
      brandDetails.brand?.equinox_link?.title ||
      getBynderAltText(brandDetails.brand?.bynder_brand_logo?.[0]) ||
      'brand logo'

    const dataAltLogoSrc =
      brandDetails.brand?.equinox_link?.title !== '' &&
      brandDetails.brand?.equinox_link?.title !== null
        ? 'cs'
        : getBynderAltTextSrc(brandDetails.brand?.bynder_brand_logo?.[0])

    const dataAltBynderBgSrc =
      brandDetails.brand?.equinox_link?.title !== '' &&
      brandDetails.brand?.equinox_link?.title !== null
        ? 'cs'
        : getBynderAltTextSrc(brandDetails.brand?.bynder_background_image?.[0])

    return {
      backgroundImage: bynderBackgroundImage || imagePlaceholder,
      brandLogo: bynderBrandLogo || imagePlaceholder,
      brandLogoAlt: logoAltText,
      brandBackGrdAlt: backGrdAltText,
      dataAltLogoSrc,
      dataAltBynderBgSrc,
    }
  }

  renderBrandCard = (brandDetails, index) => {
    const data = this.getDataModal(this.BRAND_DETAIL, brandDetails)
    const styles = this.getStyleGuide(brandDetails)

    return (
      <Card key={`brandSec-${index}`} className="brandsection">
        <PageLink
          linkInfo={data?.href}
          onClick={e => {
            this.state.isDragging && e.preventDefault()
          }}
          className="text-decoration-none">
          <div className="brand-image">
            {/* <Card.Img
              variant="top"
              className="position-relative brand-img"
              src={styles?.backgroundImage}
              alt={styles?.backgroundImageAlt}
              title={data?.title}
            /> */}
            <ImageComponent
              className="position-relative brand-img"
              src={styles?.backgroundImage}
              alt={styles?.brandBackGrdAlt}
              title={data?.title}
              // width={'230px'}
              height={'260px'}
              dataAltSrc={styles?.dataAltBynderBgSrc}
            />

            <div className="content-overlay"></div>
            <div className="brand-logo">
              {/* <Image src={styles?.brandLogo} alt={styles?.brandLogoAlt} /> */}
              <ImageComponent
                src={styles?.brandLogo}
                alt={styles?.brandLogoAlt}
                dataAltSrc={styles?.dataAltLogoSrc}
              />
            </div>
          </div>

          <Card.Body className="p-0 w-100 brand-sec-body">
            <Card.Subtitle className="head-3 heading-3">
              {data?.title}
            </Card.Subtitle>
          </Card.Body>
        </PageLink>
      </Card>
    )
  }

  getSliderSettings = () => {
    return {
      dots: true,
      appendDots: dots => this.getCustomDots(dots),
      infinite: true,
      speed: 200,
      swipeToSlide: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      onInit: this.handleTabIndex,
      afterChange: this.handleTabIndexAndGTMCarouselClick,
      arrows: false,
      beforeChange: (prev, next) => {
        /* here before change is called on didmount even when there is no action happening
         * so, we are using this function as a hack to fix the click issue
         */
        if (!this.state.isInitialSlideSet) {
          this.slider?.current?.slickGoTo?.(0)
          this.setState({ isInitialSlideSet: true })
        }
      },
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 4,
            infinite: true,
            centerMode: true,
            centerPadding: '40px',
          },
        },
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 3,
            centerMode: true,
            centerPadding: '40px',
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            centerMode: true,
            centerPadding: '130px',
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            // centerPadding: '70px',
            variableWidth: true,
          },
        },
      ],
      onSwipe: this.onSwipe.bind(this),
    }
  }

  getCustomDots = dots => {
    const customDots = []
    this.brand?.blocks?.forEach((block, index) => {
      // this.slider?.current?.slickGoTo?.(0)
      customDots.push(
        <li key={`productDot_${index}`} {...dots?.[index]?.props}>
          <button
            aria-label={`brand image ${index + 1} of ${
              this.brand?.blocks?.length
            }`}
            onClick={dots?.[index]?.props?.children?.props?.onClick}>
            {index + 1}
          </button>
        </li>
      )
    })
    return <ul className="slick-dots">{customDots}</ul>
  }

  renderArrows = () => {
    return (
      <div className="slider-arrow">
        <div
          className="arrow-btn prev"
          onClick={() => this.slider?.current?.slickPrev()}>
          <NuskinArrowLeft />
        </div>
        <div
          className="arrow-btn next"
          onClick={() => this.slider?.current?.slickNext()}>
          <NuskinArrowRight />
        </div>
      </div>
    )
  }

  render() {
    const data = this.getDataModal(this.TITLE_DETAIL)
    const settings = this.getSliderSettings()

    return (
      <Row id="brandsec" className="brandsec d-block">
        <Col md="auto" className="viewmain d-none d-md-block px-0">
          <PageLink linkInfo={data?.href} className="text-decoration-none">
            <h6 className="viewsec paragraph-m">{data?.title || ''}</h6>
          </PageLink>
        </Col>
        {this.renderArrows()}
        {data?.brandBlocks && data?.brandBlocks?.length && (
          <div>
            <Slider
              className="pb-3"
              {...settings}
              dots={true}
              ref={slider => (this.slider.current = slider)}>
              {data?.brandBlocks?.map(this.renderBrandCard)}
            </Slider>
          </div>
        )}

        <Col className="mob-view-all d-block d-md-none px-0">
          <PageLink linkInfo={data?.href} className="text-decoration-none mt-4">
            <h6 className="viewsec m-0 cta">{data?.title || ''}</h6>
          </PageLink>
        </Col>
      </Row>
    )
  }
}

export { BrandSection }
export default BrandSection
