import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { Container, Row } from 'react-bootstrap'
import ContentStack from 'src/api/contentStack'
import './styles.css'

@observer
class Disclaimer extends React.Component {
  @observable disclaimerSection = {}
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.fetchData()
  }

  fetchData = () => {
    const uid = this.props?.data?.disclaimer?.[0]?.uid || ''

    ContentStack.getReference('home_page_disclaimer', uid, this.props).then(
      response => {
        console.log(`home_page_disclaimer >>>> `, { response })
        this.disclaimerSection = response || []
      }
    )
  }

  // getDataModal = () => {
  //   return {
  //     value: this.disclaimerSection?.disclaimer || '',
  //   }
  // }

  renderDisclaimer = () => {
    // const data = this.getDataModal()
    return (
      <Row className="w-100 justify-content-center p-0 m-0">
        <div className="disclaimer-wrap">
          <i
            dangerouslySetInnerHTML={{
              __html: this.disclaimerSection?.disclaimer || '',
            }}
          />
        </div>
      </Row>
    )
  }

  render() {
    return (
      <div className="awardssec">
        <Container>
          <div>{this.renderDisclaimer()}</div>
        </Container>
      </div>
    )
  }
}
export { Disclaimer }
export default Disclaimer
