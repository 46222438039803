import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { PageLink } from 'src/views/components'
import {
  renderLinkCheck,
  getBynderImage,
  getBynderAltText,
  getBynderAltTextSrc,
} from 'src/utils'
import './styles.scss'

class HeroBanner extends React.PureComponent {
  BANNER_LEFT = 'BANNER_LEFT'
  BANNER_RIGHT_TOP = 'BANNER_RIGHT_TOP'
  BANNER_RIGHT_BOTTOM = 'BANNER_RIGHT_BOTTOM'

  constructor(props) {
    super(props)
    this.styles = {}
    this.styles[this.BANNER_LEFT] = '.hero-banner-left-inner .hero-btn'
    this.styles[this.BANNER_RIGHT_TOP] =
      '.hero-banner-right-inner button span:after'
  }

  getLink = url => {
    const originalUrl = this.props?.staticContext?.originalUrl
    return renderLinkCheck(url, originalUrl)
  }
  getBackgroundImage = options => {
    const { heroBlock, imageQueryParam } = options || {}
    return getBynderImage({
      bynderImage: heroBlock?.bynder_background_image?.[0],
      imageQueryParam,
    })
  }

  getHeroJson = options => {
    const { heroBlock, heroSection, bannerType } = options || {}

    let heroBlockJson = {}
    const linkProps = this.getLink(
      heroBlock?.equinox_link?.href || heroBlock?.link?.href || ''
    )

    const backgroundImage = this.getBackgroundImage({
      heroBlock,
      imageQueryParam: '?format=webp&io=transform:fill,width:800',
    })
    const gradient = heroSection?.main_gradient
      ? 'linear-gradient(to right top, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0)), '
      : ''

    if (heroBlock) {
      heroBlockJson = {
        linkProps,
        // bigTitle: heroBlock?.big_title || '',
        bigTitle: heroBlock?.big_title || '',
        description: heroBlock?.description || '',
        title: heroBlock?.equinox_link?.title || heroBlock?.link?.title || '',
        heading: heroBlock?.heading || '',
        main_font_color: heroSection?.main_font_color || false,
        sub_font_color: heroSection?.sub_font_color || false,
        image_description:
          heroBlock?.image_description ||
          getBynderAltText(heroBlock?.bynder_background_image?.[0]) ||
          '',
        dataAltSrc:
          heroBlock?.image_description !== '' &&
          heroBlock?.image_description !== null
            ? 'cs'
            : getBynderAltTextSrc(heroBlock?.bynder_background_image?.[0]),
        background_image: backgroundImage,
        style: {
          color: heroSection?.main_font_color ? '#fff' : '#252525',
          background: `${gradient}url(${backgroundImage})`,
        },
      }
      if (
        bannerType == this.BANNER_RIGHT_TOP ||
        bannerType == this.BANNER_RIGHT_BOTTOM
      ) {
        heroBlockJson.style = {
          color: heroSection?.sub_font_color ? '#fff' : '#252525',
          background: `${gradient}url(${backgroundImage})`,
        }
      }
      if (bannerType == this.BANNER_LEFT) {
        heroBlockJson.style = {
          color: heroSection?.main_font_color ? '#fff' : '#252525',
          background: `${gradient}url(${backgroundImage})`,
        }
      }

      if (bannerType == this.BANNER_LEFT) {
        heroBlockJson.styles_left = `.hero-banner-left-inner .hero-btn {
                background-color: ${
                  heroSection?.main_font_color ? '#fff' : '#252525'
                };
                color: ${heroSection?.main_font_color ? '#252525' : '#fff'};
              }`
      }

      // if (this.styles[bannerType]) {
      //   heroBlockJson.styles = `${this.styles[bannerType]} {
      //     background-color: ${
      //       heroSection?.main_font_color ? '#fff' : '#252525'
      //     };
      //     color: ${heroSection?.main_font_color ? '#252525' : '#fff'};
      //   }`
      // }
    }
    return heroBlockJson
  }

  getDataModal = dataKey => {
    const heroSection = this.props?.data
    let heroBlock
    switch (dataKey) {
      case this.BANNER_LEFT:
        heroBlock = this.props?.data?.hero?.[0]?.hero_block
        return this.getHeroJson({
          heroBlock: heroBlock,
          heroSection,
          bannerType: dataKey,
        })

      case this.BANNER_RIGHT_TOP:
        heroBlock = this.props?.data?.hero?.[1]?.hero_block
        return this.getHeroJson({
          heroBlock: heroBlock,
          heroSection,
          bannerType: dataKey,
        })

      case this.BANNER_RIGHT_BOTTOM:
        heroBlock = this.props?.data?.hero?.[2]?.hero_block
        return this.getHeroJson({
          heroBlock: heroBlock,
          heroSection,
          bannerType: dataKey,
        })
      default:
        return {}
    }
  }

  getStyleGuides = () => {
    const mobileBackgroundColor = this.props?.data?.background_color || ''
    const main_banner_color = this.props?.data?.main_banner_color || ''
    const main_gradient = this.props?.data?.main_gradient || ''
    let heroBlock1 = this.props?.data?.hero?.[0]?.hero_block || ''
    const backGroundImage = this.getBackgroundImage({
      heroBlock: heroBlock1,
      imageQueryParam: '?format=webp&io=transform:fill,width:600',
    })
    return {
      mobileCss:
        mobileBackgroundColor &&
        `@media (max-width: 480px) {
        .hero-banner-left-inner {
          background-color: ${mobileBackgroundColor};
          padding: 30px;
        }
        .hero-banner-section .hero-banner-left {
          height: auto;
        }
        .mob-heroleft {
          color: ${main_banner_color ? '#fff' : '#252525'};
          background: ${
            main_gradient
              ? `linear-gradient(to right top, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0)), url(${backGroundImage})`
              : `url(${backGroundImage})`
          };
          height: 309px !important;
          position: relative;
          background-repeat: no-repeat;
          background-position: right;
          background-size: cover;
        }
      }`,
    }
  }

  renderBannerRightTop() {
    const data = this.getDataModal(this.BANNER_RIGHT_TOP)
    const fontClass = data?.sub_font_color
      ? 'banner-right-text-color-white'
      : 'banner-right-text-color-black'
    return (
      <PageLink
        linkInfo={data.linkProps}
        className="d-inline-block w-100"
        data-testid="hero-subpanel-1">
        <link
          href={data?.background_image}
          as="image"
          fetchpriority="high"></link>
        <div
          className="w-100 hero-banner-right-inner one d-flex flex-column text-left justify-content-between"
          style={data?.style}
          role="img"
          data-alt-text-resolution={data?.dataAltSrc}
          aria-label={data?.image_description}>
          <p className="m-0">
            <span className="paragraph-m">{data?.heading}</span>
          </p>
          <h2 className="heading-2 m-0">{data?.bigTitle}</h2>
          {data?.description && (
            <h3 className="h5-medium mb-10 paragraph-m m-0">
              {data?.description}
            </h3>
          )}
          {<style scoped>{data?.styles}</style>}
          <div className={fontClass}>
            <Button
              variant="link"
              className="text-left p-0"
              style={{
                ...(data?.sub_font_color
                  ? { color: '#fff' }
                  : { color: '#252525' }),
              }}>
              <span className="paragraph-m">{data?.title}</span>
            </Button>
          </div>
        </div>
      </PageLink>
    )
  }

  renderBannerRightBottom() {
    const data = this.getDataModal(this.BANNER_RIGHT_BOTTOM)
    const fontClass = data?.sub_font_color
      ? 'banner-right-text-color-white'
      : 'banner-right-text-color-black'
    return (
      <PageLink
        linkInfo={data.linkProps}
        className="d-inline-block w-100"
        data-testid="hero-subpanel-2">
        <link
          href={data?.background_image}
          as="image"
          fetchpriority="high"></link>
        <div
          className="w-100 hero-banner-right-inner two d-flex flex-column text-left justify-content-between"
          style={data?.style}
          role="img"
          data-alt-text-resolution={data?.dataAltSrc}
          aria-label={data?.image_description}>
          <p className="m-0">
            <span className="paragraph-m">{data?.heading}</span>
          </p>
          <h2 className="heading-2 m-0">{data?.bigTitle}</h2>
          {data?.description && (
            <h3 className="h5-medium paragraph-m m-0">{data?.description}</h3>
          )}
          <div className={fontClass}>
            <Button
              variant="link"
              className="text-left p-0 w-auto"
              style={{
                ...(data?.sub_font_color
                  ? { color: '#fff' }
                  : { color: '#252525' }),
              }}>
              <span className="paragraph-m">{data?.title}</span>
            </Button>
          </div>
        </div>
      </PageLink>
    )
  }

  renderBannerLeft() {
    const data = this.getDataModal(this.BANNER_LEFT)
    const styles = this.getStyleGuides()
    const fontClass = data?.main_font_color
      ? 'banner-left-text-color-white'
      : 'banner-left-text-color-black'
    return (
      <div
        className="col-12 col-lg-7 p-0 hero-img-left"
        alt={data?.image_description}>
        <PageLink
          linkInfo={data.linkProps}
          className="d-inline-block w-100"
          data-testid="hero-mainpanel">
          <link
            rel="preload"
            href={data?.background_image}
            as="image"
            fetchpriority="high"></link>
          <div
            className="w-100 pr-0 hero-banner-left d-md-flex align-items-center float-left position-relative"
            style={data?.style}
            role="img"
            data-alt-text-resolution={data?.dataAltSrc}
            aria-label={data?.image_description}>
            <div className="mob-heroleft"></div>
            {styles?.mobileCss && <style scoped>{styles?.mobileCss}</style>}
            <div className="hero-banner-left-inner position-relative">
              <h2 className="heading-1">{data?.bigTitle}</h2>
              <h3 className="heading-3">{data?.description}</h3>
              {<style scoped>{data?.styles_left}</style>}
              <div className={fontClass}>
                <Button className="hero-btn">{data?.title}</Button>
              </div>
            </div>
          </div>
        </PageLink>
      </div>
    )
  }

  renderBannerRight() {
    return (
      <div className="col-12 col-lg-5 p-0 hero-banner-right d-md-flex flex-lg-column float-left">
        <div className="right-top-banner-container">
          {this.renderBannerRightTop()}
        </div>
        <div className="right-bottom-banner-container">
          {this.renderBannerRightBottom()}
        </div>
      </div>
    )
  }

  render() {
    return (
      <Container className="position-relative w-100 hero-banner-section d-lg-flex px-0">
        {this.renderBannerLeft()}
        {this.renderBannerRight()}
      </Container>
    )
  }
}

export { HeroBanner }
export default HeroBanner
