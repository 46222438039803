import React from 'react'
import {
  convertToBoolean,
  i18nTranslate,
  IS_BROWSER,
  getLiveEventStatus,
} from 'src/utils'
import { Tabs, Tab, Container } from 'react-bootstrap'
import { APPConfig } from 'config/appConfig'
import ContentStack from 'src/api/contentStack'
import { observer } from 'mobx-react'
import { customerContainer } from 'src/models'
import { observable } from 'mobx'
import { ProductSection } from '../ProductSection'
import './styles.scss'

@observer
class TabsSection extends React.Component {
  @observable productSection = []
  @observable activeTab = ''
  TITLE_DETAIL = 'TITLE_DETAIL'
  TAB_DETAIL = 'TAB_DETAIL'

  constructor(props) {
    super(props)
    this.fetchData()
  }

  fetchData = () => {
    const uid = this.props?.data?.disclaimer?.[0]?.uid || ''
    let referenceId = 'featured_product_tab'

    if (convertToBoolean(getLiveEventStatus())) {
      referenceId = 'live_event_featured_product_tab'
    }

    ContentStack.getReference(referenceId, uid, this.props).then(response => {
      this.productSection = response?.blocks || []
      this.activeTab = this.productSection?.[0]?.product_tab?.tab_title
    })
  }

  getDataModal = (dataKey, elem, index) => {
    switch (dataKey) {
      case this.TITLE_DETAIL:
        return {
          title: this.productSection?.[0]?.product_tab?.tab_title,
        }
      case this.TAB_DETAIL:
        let tabTitle = elem?.product_tab?.tab_title
        const bestSellerIndex = 0

        if (
          APPConfig?.getAppConfig?.()?.enableAWSRecommendation == 'true' &&
          customerContainer.isRegisterUser
        ) {
          if (index == bestSellerIndex) {
            tabTitle = i18nTranslate(
              'mppSection.RecommendedItems',
              'Inspired by your viewing history'
            )
          } else {
            tabTitle = i18nTranslate('mppSection.bestSellers', 'Best Sellers')
          }
        }

        return {
          eventKey: elem?.product_tab?.tab_title?.replace(/ /g, '') || '',
          title: tabTitle || '',
          data: elem?.product_tab || {},
        }
    }
  }

  getProductTabs = (elem, index) => {
    const tabData = this.getDataModal(this.TAB_DETAIL, elem, index)
    const dataTestId = [
      'mpp-best-seller-contents',
      'mpp-latest-products-contents',
    ]
    return (
      <Tab
        data-testid={dataTestId[index]}
        key={`tabsSec-${index}`}
        eventKey={tabData?.eventKey}
        title={tabData?.title}>
        <ProductSection
          data={tabData?.data}
          activeTab={this.activeTab}
          activeTabId={dataTestId[index]}
          isToShowSingleSection={this.props?.isToShowSingleSection}
          showFeaturedProducts={this.props?.showFeaturedProducts}
        />
      </Tab>
    )
  }

  addTestIds = () => {
    if (IS_BROWSER) {
      const tabs = document.querySelectorAll('.prodtabs a')
      const tabsTestIds = ['mpp-best-sellers-tab', 'mpp-latest-products-tab']

      if (tabs) {
        tabs.forEach((element, index) => {
          element.setAttribute('data-testid', tabsTestIds[index])
        })
      }
    }

    return ''
  }

  render() {
    const data = this.getDataModal(this.TITLE_DETAIL)
    return (
      <Container fluid className="tabssection px-0">
        <Tabs
          defaultActiveKey={data?.title}
          id="controlled-tab-example"
          className="prodtabs heading-5"
          onSelect={k => (this.activeTab = k)}>
          {this.productSection.map(this.getProductTabs)}
        </Tabs>
        {this.addTestIds()}
      </Container>
    )
  }
}
export { TabsSection }
export default TabsSection
