import React from 'react'

class OrderNow extends React.Component {
  render() {
    const entry = this.props.entry
    const title = entry?.big_title || 'Order Now & Pick up at L!VE'
    const description =
      entry?.description ||
      `Order online and collect at the event. Please present your order
    confirmation email containing the QR code.`
    return (
      <div className="event-ordernow-container">
        <div className="event-ordernow-main-title">{title}</div>
        <div className="event-ordernow-secondary-title">{description}</div>
      </div>
    )
  }
}

export { OrderNow }
