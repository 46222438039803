import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Row, Image, Container, Col } from 'react-bootstrap'
import Slider from 'react-slick'
import sliderSettings from './sliderSettings'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import './styles.scss'

class SuccessStoriesSection extends React.Component {
  CARD_DETAIL = 'CARD_DETAIL'
  STORY_BLOCK = 'STORY_BLOCK'

  getDataModal = (dataKey, post) => {
    switch (dataKey) {
      case this.CARD_DETAIL:
        const successStory = post?.success_story
        return {
          id: post?.id,
          image1Url: successStory?.image_1?.url || imagePlaceholder,
          image2Url: successStory?.image_2?.url || imagePlaceholder,
          heading: successStory?.heading || '',
          bigTitle: successStory?.big_title || '',
          description: successStory?.description || '',
          linkHref: successStory?.link?.href || '',
          linkTitle: successStory?.link?.title || '',
          linkSrc: '../src/images/arrow.png',
        }
      case this.STORY_BLOCK:
        return {
          blocks: this.props?.data?.blocks,
          dots: true,
        }
    }
  }

  getStyleGuides = () => {
    return {
      storyMain: {
        colLg: 7,
        colMd: 7,
        colSm: 12,
      },
      storySide: {
        colLg: 5,
        colMd: 5,
        colSm: 12,
      },
    }
  }

  renderCard = post => {
    const data = this.getDataModal(this.CARD_DETAIL, post)
    const style = this.getStyleGuides()

    return (
      <Card key={data?.id}>
        <Row>
          <Col
            lg={style?.storyMain?.colLg}
            md={style?.storyMain?.colMd}
            sm={style?.storyMain?.colSm}
            className="pr-0">
            <div className="success-main-img">
              <Image src={data?.image1Url} />
            </div>
          </Col>
          <Col
            lg={style?.storySide?.colLg}
            md={style?.storySide?.colMd}
            sm={style?.storySide?.colSm}
            className="pl-0">
            <div className="stories-text-side">
              <div className="stories-inner-text">
                <h5>{data?.heading}</h5>
              </div>
              <div className="stories-content">
                <h5>{data?.bigTitle}</h5>
                <p>{data?.description}</p>
                <Link to={data?.linkHref} className="storieslink">
                  {data?.linkTitle}
                  <img
                    src={data?.linkSrc}
                    alt="cart"
                    className="icon-cart pl-1"
                  />
                </Link>
                <Image className="thumb-img" src={data?.image2Url} />
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    )
  }

  render() {
    const settings = sliderSettings
    const blocks = this.getDataModal(this.STORY_BLOCK)

    return (
      <div className="success-section">
        <Container>
          <Slider {...settings} dots={blocks?.dots}>
            {blocks.map(this.renderCard)}
          </Slider>
        </Container>
      </div>
    )
  }
}
export { SuccessStoriesSection }
export default SuccessStoriesSection
