import React from 'react'
import { Image, Container, Row, Col, Button } from 'react-bootstrap'
import { tryJSONParse } from 'src/utils'
import './styles.scss'

class ImageAndText extends React.PureComponent {
  renderContent(widgetContent) {
    const {
      imageURL,
      alignment = 'left',
      title,
      description,
      btncontent,
    } = widgetContent

    return (
      <>
        <Row className="widget-cont">
          <Col xs={12} md={3}>
            <div className="image-cont justify-content-center align-items-center d-flex">
              <Image
                src={imageURL}
                alt="image"
                className="image mw-100 mh-100"
                fluid
              />
            </div>
          </Col>
          <Col
            xs={12}
            md={9}
            className={`text-wrap flex-column justify-content-center align-items-center d-flex p-4 ${
              alignment === 'right' ? 'right' : ''
            }`}>
            <div className="title pb-4">{title}</div>
            <div
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
            {btncontent && <Button variant="primary">{btncontent}</Button>}
          </Col>
        </Row>
      </>
    )
  }

  render() {
    const { fullWidth, ...widgetContent } = this.props.properties

    return (
      <Container className="img-text" fluid={tryJSONParse(fullWidth)}>
        {this.renderContent(widgetContent)}
      </Container>
    )
  }
}

export default ImageAndText
export { ImageAndText }
