import React from 'react'
import { i18nTranslate } from 'src/utils'
import { Container, Row, Col } from 'react-bootstrap'
import { FiArrowRightCircle as ArrowIconNew } from 'react-icons/fi'
import { ImageComponent } from 'src/views/components'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import {
  renderLinkCheck,
  getBynderImage,
  getBynderAltText,
  getBynderAltTextSrc,
} from 'src/utils'
import './styles.scss'
import { PageLink } from 'src/views/components'

class HighLighter extends React.PureComponent {
  getDataModal = () => {
    return {
      bigTitle: this.props.data?.big_title || '',
      description: this.props.data?.description || '',
      title:
        this.props.data?.equinox_link?.title ||
        this.props.data?.link?.title ||
        '',
    }
  }

  getStyleGuides = () => {
    return {
      imageURL:
        getBynderImage({ bynderImage: this.props.data?.bynder_image?.[0] }) ||
        imagePlaceholder,
      imageAlt:
        this.props.data?.image_description ||
        getBynderAltText(this.props.data?.bynder_image?.[0]) ||
        i18nTranslate('highlightImage', 'Highlight Image', {
          nameSpace: 'ssr-resource',
        }),
      href: renderLinkCheck(
        this.props.data?.equinox_link?.href || this.props.data?.link?.href
      ),
      imageALtResolution:
        this.props.data?.image_description !== '' &&
        this.props.data?.image_description !== null
          ? 'cs'
          : getBynderAltTextSrc(this.props.data?.bynder_image?.[0]),
    }
  }

  render() {
    const data = this.getDataModal()
    const styles = this.getStyleGuides()

    return (
      <Container fluid className="highlight-section">
        <Row className="highlight-section-1">
          <Col className="highlight-image px-0">
            {/* <Image src={styles?.imageURL} alt={styles?.imageAlt} /> */}
            <ImageComponent
              src={styles?.imageURL}
              alt={styles?.imageAlt}
              dataAltSrc={styles?.imageALtResolution}
            />
          </Col>
          <Col className="highlight-section-content">
            <h2 className="heading-2">{data?.bigTitle}</h2>
            <p className="paragraph-m pb-1">{data?.description}</p>
            <PageLink
              className="paragraph-m"
              linkInfo={styles?.href}
              target="_blank">
              <span>{data?.title}</span>
              <ArrowIconNew className="arrow-icon1 pl-2" />
            </PageLink>
          </Col>
        </Row>
      </Container>
    )
  }
}

export { HighLighter }
export default HighLighter
