import * as yup from 'yup'
import { getEmailSchema } from 'src/deps'

const schemaData = Object.assign(
  {},
  getEmailSchema({
    inputName: 'email',
  })
)

const schema = yup.object(schemaData)

export { schema }
