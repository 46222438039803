import React from 'react'
import { withTranslation } from 'react-i18next'
import { HeroBanner } from './HeroBanner'
import { OrderNow } from './OrderNow'
import { OceanBanner } from './OceanBanner'
import { FeaturedProducts } from './FeaturedProducts'
import { TabsSection } from 'src/views/studioComponents'
import { TrMeBanner } from './TrMeBanner'
import { i18nTranslate } from 'src/utils'
import { MetaDecorator } from 'src/views/components'
import { EventRichText } from './EventRichText'
import './styles.scss'

class EventStudioPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showFeaturedProducts: false,
    }
  }

  showFeaturedProducts = () => {
    this.setState({ showFeaturedProducts: true })
  }

  render() {
    const metaTitle = 'L!VE 2024 | Nu Skin'
    const studioComponents = this.props.studioEntries.map(entry => {
      if (entry?.hero) {
        return <HeroBanner entry={entry?.hero} />
      } else if (entry?.showcase) {
        return <OrderNow entry={entry?.showcase} />
      } else if (entry?.live_event_featured_products) {
        // return <FeaturedProducts entry={entry['featured_products']} />
        return (
          <div
            className={`event-featured-container ${
              this.state.showFeaturedProducts ? '' : 'd-none'
            }`}>
            <div className="event-featured-title">
              {i18nTranslate('event.homeFeatured', 'Featured Products', {
                nameSpace: 'ssr-resource',
              })}
            </div>
            <TabsSection
              data={entry?.live_event_featured_products}
              isToShowSingleSection={true}
              showFeaturedProducts={this.showFeaturedProducts}
            />
          </div>
        )
      } else if (entry?.highlight_block) {
        if (entry?.highlight_block?.image_position == 'right') {
          return <OceanBanner entry={entry?.highlight_block} />
        }
        return <TrMeBanner entry={entry?.highlight_block} />
      } else if (entry?.rich_text) {
        return <EventRichText entry={entry?.rich_text} />
      }
      return <></>
    })

    return (
      <div className="event-home-container">
        <MetaDecorator title={metaTitle}></MetaDecorator>
        {studioComponents}
      </div>
    )
  }
}

export { EventStudioPage }
export default withTranslation(['ssr-resource'])(EventStudioPage)
