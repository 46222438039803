import React from 'react'
import { Container, Button } from 'react-bootstrap'
import { CommonForm } from 'src/views/components'
import { schema } from './validationSchema'
import './styles.scss'

class FormBuilder extends React.Component {
  handleSubmitButton = () => {
    // @todo
  }

  renderSubmitButton = () => {
    const buttonLabel = this.props?.properties?.buttonLabel || 'Submit'
    return (
      <Button variant="primary" type="submit" className="formbuilder-button">
        {buttonLabel}
      </Button>
    )
  }

  renderFormInput = () => {
    /**
     * @todo need to render placeholder dynamically using locale
     */
    return [
      [
        {
          name: 'email',
          ariaLabel: 'email',
          isToHideLabel: true,
          placeholderKey: 'emailAddressPlaceHolder',
          customClassName: 'formbuilder mb-1',
          className: 'formbuilder-input',
          columnType: {
            sm: '8',
            xs: '12',
          },
        },
        {
          name: 'submitButton',
          type: 'inlineSubmitButton',
        },
      ],
    ]
  }

  render() {
    const widgetName = this.props?.widgetName
    const title = this.props?.properties?.title || 'Email'
    return (
      <Container className={`formbuilder-wrappe ${widgetName}`}>
        <h4 className="formbuilder-title">{title}</h4>
        <CommonForm
          schema={schema}
          formTypeJson={this.renderFormInput()}
          SubmitButtonProps={this.renderSubmitButton}
          onSubmit={this.handleSubmitButton}
          hasInlineSubmitButton={true}
          classNameValue={{
            formClassName: 'form-builder-form col-lg-6 col-md-8 col-sm-12',
          }}
        />
      </Container>
    )
  }
}

export { FormBuilder }
