import React from 'react'
import { Container, Row, Button } from 'react-bootstrap'
import {
  MdShare as ShareIcon,
  MdPlayCircleFilled as VideoPlayIcon,
} from 'react-icons/md'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { i18nTranslate } from 'src/utils'
import { videoSchema } from 'src/models'
import { JsonLd, HeaderLogo } from 'src/views/components'
import { videoList } from './fixture'
import './styles.scss'

@observer
class Video extends React.Component {
  @observable isPlaying = false
  renderButtons = list => {
    const handleClick = () => {
      this.isPlaying = true
    }
    if (list.shouldShowVideoInformation === 'false') {
      return (
        <Button
          onClick={handleClick}
          variant="transparent"
          className="video-player-button"
          aria-label={i18nTranslate('video.playButton', 'video player button')}>
          <div className="video-player-icon-wrapper text-white">
            <VideoPlayIcon
              className="w-100 h-auto"
              aria-label={i18nTranslate('icons.playIcon', 'Play icon', {
                nameSpace: 'ssr-resource',
              })}
            />
          </div>
        </Button>
      )
    }
    return (
      <>
        <Button
          onClick={handleClick}
          variant="transparent"
          className="video-player-button"
          aria-label={i18nTranslate('video.playButton', 'video player button')}>
          <div className="video-player-icon-wrapper text-white">
            <VideoPlayIcon
              className="w-100 h-auto"
              aria-label={i18nTranslate('icons.playIcon', 'Play icon', {
                nameSpace: 'ssr-resource',
              })}
            />
          </div>
        </Button>
        <section className="video-poster-text">
          <HeaderLogo />
          <header className="title-text">{list.title}</header>
          <p className="slogan-text d-none d-sm-block">{list.slogan}</p>
        </section>
        <Row className="share-wrapper" noGutters>
          <span className="share-title">{list.shareTitle}</span>
          <div className="share-icon">
            <ShareIcon
              className="w-100 h-auto"
              aria-label={i18nTranslate('icons.shareIcon', 'Share icon', {
                nameSpace: 'ssr-resource',
              })}
            />
          </div>
        </Row>
        <div className="video-length-wrap">
          <span>{list.videoLength}</span>
        </div>
      </>
    )
  }
  renderVideoList = list => {
    const {
      poster = '',
      src,
      shouldLoop,
      shouldAutoPlay,
      hasNativeControls,
    } = list
    const attributes = {
      poster: poster ? poster.concat('?format=webp') : '',
      src,
      className: 'video w-100',
    }
    if (shouldAutoPlay === 'true' || this.isPlaying) {
      attributes.autoPlay = shouldAutoPlay === 'true' || this.isPlaying
    }
    if (
      poster === '' ||
      this.isPlaying ||
      (shouldAutoPlay === 'true' && hasNativeControls === 'true')
    ) {
      attributes.controls = hasNativeControls === 'true' || this.playing
    }
    if (shouldLoop === 'true') {
      attributes.loop = true
    }
    const videoList = (
      <video
        {...attributes}
        aria-label={i18nTranslate('video.videopanel', 'Video Panel')}>
        <source src={list?.src} />
      </video>
    )
    return videoList
  }
  render() {
    const videoWidgetProps = this.props?.properties || {} || videoList()
    const schemaData = videoSchema(videoWidgetProps)
    const widgetName = this.props?.widgetName

    return (
      <>
        <Row
          noGutters
          className={`video-panel-wrapper  position-relative px-2 px-md-0 text-white ${widgetName}`}>
          <Container className="video-panel-container p-0 position-relative">
            {this.renderVideoList(videoWidgetProps)}
            {this.isPlaying || videoWidgetProps.shouldAutoPlay === 'true'
              ? ''
              : this.renderButtons(videoWidgetProps)}
          </Container>
        </Row>
        <JsonLd data={schemaData} key="schema-video" />
      </>
    )
  }
}

export default Video
export { Video }
