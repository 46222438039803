import React from 'react'
import { Col } from 'react-bootstrap'
import './styles.scss'

class ProductsPlaceHolder extends React.PureComponent {
  render() {
    const defaultColumnType = { xs: '6', md: '4', lg: '3' }
    const {
      products = [],
      columnType = defaultColumnType,
      containerClsName = 'mpp-placeholder',
      columnClassName = 'mb-2',
    } = this.props || {}

    if (products?.length <= 0) {
      return <></>
    }

    return (
      <div className={containerClsName}>
        {products.map((product, i) => (
          <Col {...columnType} key={i} className={columnClassName}>
            <div className="product-card">
              <div className="image mb-2"></div>
              <div className="content">
                <h2></h2>
                <p></p>
              </div>
            </div>
          </Col>
        ))}
      </div>
    )
  }
}

export { ProductsPlaceHolder }
export default ProductsPlaceHolder
