import React from 'react'
import parse from 'html-react-parser'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { IS_BROWSER } from 'config/appConfig'
import { customerContainer } from 'src/models'

@observer
class EventRichText extends React.Component {
  @observable rteContent = ''

  constructor(props) {
    super(props)

    this.updateContent()
  }

  checkAndInit = () => {
    const customerResponse =
      customerContainer?.profileResponse?.customer ||
      customerContainer?.profileResponse
    if (customerResponse?.id) {
      this.updateContent()
    } else {
      setTimeout(() => {
        this.checkAndInit()
      }, 300)
    }
  }

  componentDidMount() {
    this.checkAndInit()
  }

  updateContent() {
    const userName = customerContainer?.profileResponse?.firstName || ''
    const csContent =
      this.props.entry?.rich_text_editor?.replace('[name]!', userName) || ''
    this.rteContent = csContent || ''
  }

  render() {
    return (
      <div className="event-rte-container">{parse(this.rteContent || '')}</div>
    )
  }
}

export default EventRichText
export { EventRichText }
