import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { Card, Row, Container, Col } from 'react-bootstrap'
import Slider from 'react-slick'
import TagManager from 'react-gtm-module'
import ContentStack from 'src/api/contentStack'
import NuskinArrowRight from 'src/views/components/Icons/NuskinArrowRight'
import NuskinArrowLeft from 'src/views/components/Icons/NuskinArrowLeft'
import { IS_BROWSER } from 'config/appConfig'
import {
  renderLinkCheck,
  getBynderImage,
  getBynderAltText,
  getBynderAltTextSrc,
} from 'src/utils'
import { ImageComponent } from 'src/views/components'
import { imagePlaceholder } from 'src/static/fixtures/imagePlaceholder'
import './styles.css'
import { PageLink } from 'src/views/components'

@observer
class NewsSection extends React.Component {
  @observable news = {}
  @observable newsSection = []

  TITLE_INFO = 'TITLE_INFO'
  DETAIL_INFO = 'DETAIL_INFO'
  mailTitle = 'newssec'

  constructor(props) {
    super(props)
    this.slider = React.createRef(null)
    this.state = { isDragging: false, isInitialSlideSet: false }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = () => {
    const uid = this.props?.data?.disclaimer?.[0]?.uid || ''

    ContentStack.getReference('news_slider', uid, this.props).then(response => {
      if (response && response?.news_blocks) {
        this.news = response
        this.newsSection = response.news_blocks
        this.setState({ isDragging: false })
      }
    })
  }

  // fix for ADA as child anchor tags are also included in tab navigation
  // inside child elements
  methodToReuseAdaFix = () => {
    if (IS_BROWSER) {
      let anchorTags
      this.setTimer = setTimeout(() => {
        anchorTags = document.querySelectorAll(
          `#${this.mailTitle} .slick-list a`
        )
      }, 700)

      if (anchorTags?.length > 0) {
        this.handleADA()
      } else {
        if (this.setTimer) {
          clearTimeout(this.setTimer)
        }

        this.setTimer = setTimeout(() => {
          this.handleADA()
        }, 700)
      }
    }
  }

  handleADA = () => {
    const slickLists = document.querySelectorAll(
      `#${this.mailTitle} .slick-list .slick-slide`
    )
    slickLists?.forEach(elem => {
      const isHidden = elem.getAttribute('aria-hidden') || ''
      const aTags = elem.getElementsByTagName('a')[0] || ''
      if (isHidden === 'true') {
        if (aTags) {
          aTags.setAttribute('tabindex', '-1')
        }
      } else if (isHidden === 'false') {
        if (aTags) {
          aTags.removeAttribute('tabindex')
        }
      }
    })
  }

  handleTabIndex = () => {
    this.methodToReuseAdaFix()
  }

  handleTabIndexAndGTMCarouselClick = () => {
    // after slide change
    this.setState({ isDragging: false })
    this.methodToReuseAdaFix()
    TagManager.dataLayer({
      dataLayer: {
        event: 'carouselClicked',
        pagePath: '/',
        pageTitle: 'Home',
        section: 'Nu Skin In The News',
        platform: 'equinox',
      },
    })
  }

  onSwipe = () => {
    if (this.slider?.current) {
      this.slider.current.innerSlider.clickable = true
    }
    this.setState({ isDragging: true })
  }

  getDataModal = (dataKey, post = {}) => {
    switch (dataKey) {
      case this.TITLE_INFO:
        return {
          imageURL:
            getBynderImage({
              bynderImage: this.props?.data?.bynder_image?.[0],
            }) || imagePlaceholder,
          imageDescription:
            this.props?.data?.image_description ||
            getBynderAltText(this.props?.data?.bynder_image?.[0]) ||
            '',
          link:
            this.props?.data?.equinox_link?.href ||
            this.props?.data?.link?.href ||
            '',
          title:
            this.props?.data?.equinox_link?.title ||
            this.props?.data?.link?.title ||
            '',
          imageAltResolution:
            this.props?.data?.image_description !== '' &&
            this.props?.data?.image_description !== null
              ? 'cs'
              : getBynderAltTextSrc(this.props.data?.bynder_image?.[0]),
        }
      case this.DETAIL_INFO:
        return {
          imageURL:
            getBynderImage({
              bynderImage: post?.news_block?.bynder_image?.[0],
            }) || imagePlaceholder,
          newsLink:
            post?.news_block?.equinox_link?.href ||
            post?.news_block?.link?.href ||
            '',
          imageDescription:
            post?.news_block?.image_description ||
            getBynderAltText(post?.news_block?.bynder_image?.[0]) ||
            '',
          title:
            post?.news_block?.equinox_link?.title ||
            post?.news_block?.link?.title ||
            '',
          newsHeadline: post?.news_block?.headline,
          imageAltResolution:
            post?.news_block?.image_description !== '' &&
            post?.news_block?.image_description !== null
              ? 'cs'
              : getBynderAltTextSrc(post?.news_block?.bynder_image?.[0]),
        }

      default:
        return {}
    }
  }

  getStyleGuides = () => {
    return {
      dots: true,
      appendDots: dots => this.getCustomDots(dots),
      infinite: true,
      speed: 200,
      slidesToShow: 3,
      swipeToSlide: true,
      slidesToScroll: 1,
      onInit: this.handleTabIndex,
      afterChange: this.handleTabIndexAndGTMCarouselClick,
      arrows: false,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '65px',
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '120px',
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '65px',
          },
        },
      ],
      onSwipe: this.onSwipe.bind(this),
      //
      beforeChange: (prev, next) => {
        /* here before change is called on didmount even when there is no action happening
         * so, we are using this function as a hack to fix the click issue
         */
        if (!this.state.isInitialSlideSet) {
          this.slider?.current?.slickGoTo?.(0)
          this.setState({ isInitialSlideSet: true })
        }
      },
    }
  }

  getCustomDots = dots => {
    const customDots = []
    this.newsSection?.forEach((block, index) => {
      // this.slider?.current?.slickGoTo?.(0)
      customDots.push(
        <li key={`productDot_${index}`} {...dots?.[index]?.props}>
          <button
            aria-label={`news image ${index + 1} of ${
              this.newsSection?.length
            }`}
            onClick={dots?.[index]?.props?.children?.props?.onClick}>
            {index + 1}
          </button>
        </li>
      )
    })
    return <ul className="slick-dots">{customDots}</ul>
  }

  renderArrows = () => {
    return (
      <div className="slider-arrow">
        <div
          className="arrow-btn prev"
          onClick={() => this.slider?.current?.slickPrev()}>
          <NuskinArrowLeft />
        </div>
        <div
          className="arrow-btn next"
          onClick={() => this.slider?.current?.slickNext()}>
          <NuskinArrowRight />
        </div>
      </div>
    )
  }

  renderNews = (post, index) => {
    const data = this.getDataModal(this.DETAIL_INFO, post)

    return (
      <Card key={`newsSec-${index}`} className="news-slider-card">
        <PageLink
          linkInfo={renderLinkCheck(data?.newsLink)}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => {
            if (this.state.isDragging) {
              e.preventDefault()
            }
          }}
          className="text-decoration-none">
          {/* <Card.Img
              className="newsImg"
              variant="top"
              src={data?.imageURL}
              alt={data?.imageDescription}
              title={data?.title}
            /> */}

          <ImageComponent
            src={data?.imageURL}
            alt={data?.imageDescription}
            title={data?.title}
            dataAltSrc={data?.imageAltResolution}
          />
          <Card.Body style={{ backgroundColor: '#fff' }}>
            <div className="news-sec-desc">
              <Card.Subtitle className="card-subtitle paragraph-l">
                {data?.newsHeadline}
              </Card.Subtitle>
              <Card.Text>
                <span className="news-date paragraph-m">{data?.title}</span>
              </Card.Text>
            </div>
          </Card.Body>
        </PageLink>
      </Card>
    )
  }

  render() {
    const data = this.getDataModal(this.TITLE_INFO)
    const settings = this.getStyleGuides()
    return (
      <div id="newssec">
        <div className="news-section">
          <Container>
            <div className="news-section-inner">
              <Row className="pt-2">
                <Col lg={4} md={3} sm={12} className="news-left-image">
                  <div className="news-sidebar">
                    {/* <Image src={data?.imageURL} alt={data?.imageDescription} /> */}
                    <ImageComponent
                      src={data?.imageURL}
                      alt={data?.imageDescription}
                      dataAltSrc={data?.imageAltResolution}
                    />
                  </div>
                </Col>
                <Col lg={8} md={9} sm={12} className="news-slider-right px-0">
                  <div className="news-slider-view-all">
                    <PageLink
                      linkInfo={renderLinkCheck(data?.link)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="news-view-all-btn text-decoration-none">
                      <h6 className="m-0 paragraph-m">{data?.title}</h6>
                    </PageLink>
                  </div>
                  {this.renderArrows()}
                  {this.newsSection.length && (
                    <div className="newsslider">
                      <Slider
                        {...settings}
                        dots={true}
                        ref={slider => (this.slider.current = slider)}>
                        {this.newsSection.map(this.renderNews)}
                      </Slider>
                    </div>
                  )}
                  <div className="news-slider-view-all-mobile">
                    <PageLink
                      linkInfo={renderLinkCheck(data?.link)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="news-view-all-btn text-decoration-none">
                      <h6 className="m-0 paragraph-m">{data?.title}</h6>
                    </PageLink>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    )
  }
}
export { NewsSection }
export default NewsSection
